import React, { useState, useEffect } from 'react';
import './Amistad_DatosContrato.css';
import {Logo2} from "./Amistad_Logos.jsx";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
  useLocation,
} from "react-router-dom";

const LogoAmistad = Logo2;

// console.log("cargando Logos Contrato", Logo2);

var datosContrato = {
   "pago-asesores": {
      claveA: "CLAVE DE ASESOR",
      nombreA: "NOMBRE DEL ASESOR"
   },
   "": {
      claveA: "NÚMERO DE CONTRATO",
      nombreA: "NOMBRE DEL CLIENTE"
   },
};

function Amistad_DatosContrato() {
   var { idDatosContrato } = useParams();
   var datosContr = datosContrato[idDatosContrato] ?? {};

   var [contratoNombre, setContratoNombre] = useState(window.localStorage.contrato_nombre);
   
   return (
      <div className="amistad_datosContrato-body">
         <div className="amistad_datosC-container">
            <div className="amistad_datosC-Img">
               <img src={LogoAmistad} alt="imagen amistad logo" />
            </div>
            <div className="amistad_datosC-inputs">
               <div className="amistad_datosC-content centrar">
                  <h1>{datosContr.claveA}</h1>
                  <div className="amistad_separador"></div>
                  <input type="text" value={window.localStorage.contrato} />
               </div>
               <div className="amistad_datosC-content nombre">
                  <h1>{datosContr.nombreA}</h1>
                  <div className="amistad_separador"></div>
                  <input type="text" value={contratoNombre}
                     // onChange={e=>setContratoNombre(e.target.value)}
                  />
               </div>
            </div>
         </div>
      </div>
   )
}

export default Amistad_DatosContrato
