import React, { useEffect, useState, useRef } from "react";
import "./Bienvenida.css";
import Amistad_Logos, { esMonclova, Logo2 } from "./Amistad_Logos";
import amistadlogo from "../res/amistadlogo.png";
import Popup from "reactjs-popup";
import ReportarDeposito from "../components/reportar-deposito";
import ContratoImg from "../res/contrato-imagen.png";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import TerminosCondiciones from "./terminos-condiciones";
import {
  BuscarNoContrato,
  ObtenerContrato,
  ObtenerFraccionamientosEncabezados,
  ValidarContratoRegistro,
} from "../back_helper";
import bienvenidaTarjeta from "../res/contrato-imagen-min.png";
import Amistad_Footer from "./Amistad_Footer";
import logoSantander from "../res/web/logo_santander.png";
import logoGetnet from "../res/web/getnet_logo1.png";
import Logo26y from "../res/movil/26years.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

window.Swal = Swal;

function Bienvenida({ history }) {
  const [datosContrato, setDatosContrato] = useState({
    nombre: "",
    contrato: "",
  });

  const [modal, setModal] = useState(null);
  const toggle = async () => {
    setModal(!modal);
  };

  const [modal2, setModal2] = useState(null);
  const toggle2 = async () => {
    setModal2(!modal2);
  };

  const noContratoRef = useRef();
  const [noContrato, setNocontrato] = useState("");
  const [fraccionamientos, setFraccionamientos] = useState([]);

  const [open1, setOpen1] = useState(false);
  const CerrarModal1 = () => setOpen1(false);
  const busquedaDataRef = useRef({});

  console.log("NOCONTRATO", noContrato.toUpperCase());

  const validarContrato = async () => {
    var res = await ValidarContratoRegistro(noContrato.toUpperCase());
    if (!res.existe) {
      Swal.fire({
        icon: "error",
        title: "No se encontró ese número de contrato",
      });
      return;
    }
    if (res.registrado) {
      toggle();
    }
    if (!res.registrado) {
      toggle2();
    }

    // console.log(res, "REEESPUESTA");
  };
  // const BuscarContrato = async () => {
  //   setOpen1(true);
  //   var nocontrato = noContratoRef.current.value;
  //   console.log(nocontrato);

  //   var contrato = await ObtenerContrato(nocontrato);

  //   console.log("contrato", contrato);

  //   if (!contrato || contrato.length == 0) {
  //     setOpen1(false);
  //     Swal.fire({
  //       icon: "error",
  //       title: "No se encontró ese número de contrato",
  //     });

  //     return;
  //   }

  //   contrato = contrato[0];
  //   console.log("contrato", contrato);

  //   window.localStorage.contrato = contrato.contrato;
  //   window.localStorage.contrato_nombre = contrato.nombre;

  //   setOpen1(false);
  //   history.push("/seleccionar-pago");
  // };

  const ActualizarFraccionamientos = async () => {
    var fraccionamientos = await ObtenerFraccionamientosEncabezados();
    setFraccionamientos(fraccionamientos);
  };

  const [open2, setOpen2] = useState(false);
  const CerrarModal2 = () => setOpen2(false);
  const AbrirModal2 = () => setOpen2(true);

  const [openTerminos, setOpenTerminos] = useState(false);
  const AbrirModalTerminos = () => setOpenTerminos(true);
  const CerrarModalTerminos = () => setOpenTerminos(false);

  const SeleccionarContrato = () => {};

  const [open3, setOpen3] = useState(false);
  const CloseModal3 = () => setOpen3(false);
  const OpenModal3 = () => setOpen3(true);

  const [open4, setOpen4] = useState(false);
  const CloseModal4 = () => setOpen4(false);
  const OpenModal4 = () => {
    busquedaDataRef.current = {};
    ActualizarFraccionamientos();
    setOpen4(true);
  };

  const BuscarClaveContrato = async () => {
    CloseModal4();
    var data = busquedaDataRef.current;
    var res = await BuscarNoContrato(
      data.primer_nombre ?? "",
      data.segundo_nombre ?? "",
      data.apellido_paterno ?? "",
      data.apellido_materno ?? "",
      data.fraccionamiento ?? "",
      data.manzana ?? "",
      data.lote ?? ""
    );
    if (res.error) {
      Swal.fire({
        icon: "error",
        title:
          "No se encontró el contrato que coincida con los datos ingresados",
      });
      return;
    }
    noContratoRef.current.value = res.contrato ?? "";
  };

  var sucursal = /mva/.test(window.location.hostname) ? "monclova" : "cancun"

  const setCampo = (nombre, valor) => {
    busquedaDataRef.current[nombre] = valor;
    console.log(nombre, valor);
  };

  useEffect(() => {
    window.location.href = "https://portal.amistadbr.com/"
  }, [])
  

  return (
    <>
      <div className="bienvenida-main amistadFondo">
        <Amistad_Logos></Amistad_Logos>
        <div className="bienvenida-container">
          <div className="bienvenida-content">
            <h1>
              Bienvenidos <br />
            </h1>
            <span>pagos en línea amistad</span>
            <h4>Ingresa la clave de tu contrato</h4>
            <div className="bienvenida-container-inputs">
              <div className="bienvenidad-inputIcon">
                <input
                  type="text"
                  onChange={(e) => setNocontrato(e.target.value)}
                />
                <i className="fas fa-info" onClick={OpenModal3}></i>
              </div>
              <div className="bienvenida-btn" onClick={validarContrato}>
                {/* <a href="/seleccionar-pago"> */}
                <button>Siguiente</button>
              </div>
            </div>

            <div
              className="bienvenida-teminosC"
              style={{ textAlign: "center", marginTop: "0.3rem" }}
            >
              Al dar en "SIGUIENTE" aceptas nuestros{" "}
              <span
                onClick={AbrirModalTerminos}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                Términos, condiciones y política de privacidad
              </span>
            </div>
            <div
              className="bienvenida-RecuperarContrato"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              ¿Olvidaste la clave de tu contrato?{" "}
              <span
                onClick={OpenModal4}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                Recuperar clave de contrato
              </span>
            </div>
            <div className="bienvenida-imgSantander">
              <h5>Con el respaldo del Grupo Financiero Internacional: </h5>
              <img src={logoSantander} alt="imagen logo santander" />
              <img src={logoGetnet} alt="imagen Getnet santander" />
              <br />
              <img
                className="amistad-26y"
                src={Logo26y}
                alt="imagen 26 años"
                style={{ width: "auto", height: "100px" }}
              />
            </div>
            <Popup
              open={openTerminos}
              modal
              closeOnDocumentClick
              onClose={CerrarModalTerminos}
            >
              <div
                className="modal"
                style={{ maxHeight: "90vh", overflow: "auto" }}
              >
                <a className="close" onClick={CerrarModalTerminos}>
                  &times;
                </a>
                <div className="amistad-terminosC">
                  <TerminosCondiciones></TerminosCondiciones>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <Popup open={open1} modal closeOnDocumentClick onClose={CerrarModal1}>
          <div className="modal">
            <a className="close" onClick={CerrarModal1}>
              &times;
            </a>
            <div className="portal-boton-contrato-popup">
              <i className="far fa-clock"></i>
              <div className="portal-boton-contrato-popuptext">
                Estamos verificando sus datos, espere un momento...
              </div>
            </div>
          </div>
        </Popup>

        <Popup open={modal} modal>
          <div className="modal">
            <a className="close" onClick={toggle}>
              &times;
            </a>
            <div className="contentPortal">
              <span>
                Estimado usuario te recordamos que para proteger los datos de tu
                cuenta es importante ingresar con tu correo y contraseña.
              </span>
              <a className="button" href="https://portal.amistadbr.com/login">
                Continuar
              </a>
            </div>
          </div>
        </Popup>

        <Popup open={modal2} modal>
          <div className="modal">
            <a className="close" onClick={toggle2}>
              &times;
            </a>
            <div className="contentPortal2">
              <span>
                Hemos renovado el portal de pagos para mejorar tu experiencia.
                <br />
                ahora para acceder a el será necesario elegir un
                correo y contraseña
              </span>
              <a className="button" href={`https://portal.amistadbr.com/register?contrato=${noContrato}&sucursal=${sucursal}`}>
                Continuar
              </a>
              {/* <div className="form">
                <label>
                  Correo electrónico:
                  <input
                    type="email"
                    name="email"
                    // value={form.email}
                    // onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Confirmar correo electrónico:
                  <input
                    type="email"
                    name="confirmEmail"
                    // value={form.confirmEmail}
                    // onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Contraseña:
                  <input
                    type="password"
                    name="password"
                    // value={form.password}
                    // onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Confirmar contraseña:
                  <input
                    type="password"
                    name="confirmPassword"
                    // value={form.confirmPassword}
                    // onChange={handleChange}
                    required
                  />
                </label>
                <div className="button">Continuar</div>
              </div> */}
            </div>
          </div>
        </Popup>

        <Popup
          className="tarjeta"
          open={open3}
          modal
          closeOnDocumentClick
          closeOnEscape
          onClose={CloseModal3}
        >
          <div className="popup-body">
            <a className="close" onClick={CloseModal3}>
              &times;
            </a>
            <div className="bienvenida-popupImg">
              <img
                src=" https://img.mxs.io/Amistad/amistad-contrato.jpeg"
                alt="tarjeta ejemplo"
              />
            </div>
          </div>
        </Popup>

        <Popup
          className="popup-recuperarC"
          open={open4}
          closeOnEscape
          closeOnDocumentClick
          onClose={CloseModal4}
        >
          <div className="popup-body2">
            <a className="close" onClick={CloseModal4}>
              &times;
            </a>
            <div className="bienvenida-popupRecuperarC">
              <h1>Ingresa los siguientes datos a continuación: </h1>
              <div className="bienvenida-popupDatosR">
                <label htmlFor="">Primer nombre</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("primer_nombre", e.target.value)}
                />
                <label htmlFor="">Segundo nombre</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("segundo_nombre", e.target.value)}
                />
                <label htmlFor="">Apellido paterno</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("apellido_paterno", e.target.value)}
                />
                <label htmlFor="">Apellido materno</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("apellido_materno", e.target.value)}
                />
                <label htmlFor="">Fraccionamiento</label>
                <select
                  onChange={(e) => setCampo("fraccionamiento", e.target.value)}
                >
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {!fraccionamientos
                    ? ""
                    : fraccionamientos.map((f) => (
                        <option key={"frac-" + f.nombre} value={f.nombre}>
                          {f.nombre.replace("FRACCIONAMIENTO ", "")}
                        </option>
                      ))}
                </select>
                <label htmlFor="">Manzana</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("manzana", e.target.value)}
                />
                <label htmlFor="">Lote</label>
                <input
                  type="text"
                  onChange={(e) => setCampo("lote", e.target.value)}
                />
              </div>
              <div className="bienvenida-popupDatosR-btn">
                <button onClick={CloseModal4}>Regresar</button>
                <button onClick={BuscarClaveContrato}>Buscar</button>
              </div>
            </div>
          </div>
        </Popup>
        {/* <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        fullscreen
        centered
        size="lg"
      >
        <ModalBody>
        </ModalBody>
      </Modal> */}
      </div>
      <Amistad_Footer></Amistad_Footer>
    </>
  );
}

export default withRouter(Bienvenida);
