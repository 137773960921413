import React, { useEffect } from "react";
import "./Pago_Servicios.css";
import Amistad_Logos from "./Amistad_Logos";
import ImgSelecc from "../res/png_iconos/Seleccionar.png";
import { useState } from "react";
import * as date_fns from 'date-fns'
import { abrirEnlaceSelf, CrearEnlacePagoServicios, formatMoney, ObtenerCalendarioServicio } from "../back_helper";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import Amistad_Footer from "./Amistad_Footer";
import Amistad_DatosContrato from "./Amistad_DatosContrato";

function Pago_Servicios() {
  var [state, setState] = useState({
    registros : [],
    subtotal: 0,
    recargos: 0,
    total: 0,
    mensualidades: ""
  });

  const location = useLocation();

  const cargarMensualidades = async () => {
    var mensualidades = await ObtenerCalendarioServicio(window.localStorage.contrato, window.localStorage.servicio);
    state.registros = mensualidades;
    seleccionar(mensualidades[0]);
  } 

  useEffect(() => {
    cargarMensualidades();
  }, []);



  const seleccionar = (registro) => {
    var seleccionado = true;
    
    var total = 0;
    var subtotal = 0;
    var recargos = 0;
    var registros = state.registros;
    var mensualidades = "";

    for (let i = 0; i < registros.length; i++) {
      const e = registros[i];
      e.seleccionado = seleccionado;
      
      if(seleccionado)
      {
        subtotal += e.cantidad;
        total += e.cantidad + e.recargo;
        recargos += e.recargo;

        if(mensualidades != "") mensualidades += ",";
        mensualidades += e.nuMensualidad;
      }

      if (e == registro) {
        seleccionado = false;
      }
    }
    setState({
      registros: [...registros],
      total : total,
      subtotal: subtotal,
      recargos: recargos,
      mensualidades: mensualidades
    });
  };

  var procesando = false;
  const realizarPago = async () => {   
    if(procesando) return;
    procesando = true;   
    window.localStorage.ultimo_checkout = location.pathname;
    
    var swalData = await Swal.fire( {
      title: 'Para continuar, ingrese los siguientes datos',
      html:"<div>Te invitamos a ingresar tus datos para recibir el comprobante de tu pago</div><div class='b'><p>Correo</p></div><input id='swal-input2' class='swal2-input' required/><div class='b'><p>Teléfono</p></div><input id='swal-input1' class='swal2-input' autofocus minlength='500' >",
      preConfirm: function() {
          return new Promise((resolve, reject) => {
              // get your inputs using their placeholder or maybe add IDs to them
              resolve({
                  telefono: document.getElementById("swal-input1").value,
                  correo: document.getElementById("swal-input2").value
              });

              // maybe also reject() on some condition
          });
      },
      confirmButtonText: 'Continuar'	
    });
    if(!swalData.value) swalData.value = {};

    var data = await CrearEnlacePagoServicios(window.localStorage.contrato, window.localStorage.servicio, state.mensualidades, swalData.value.correo, swalData.value.telefono);
    if(data.error)
    {
      Swal.fire({
         icon: "error",
         title: data.error,
      });
      procesando = false;
      return;
    }
    abrirEnlaceSelf(data.enlace);
  }
  
  // console.log("registros", state.registros);

  return (
    <div className="pagoServ-main amistadFondo invertido">
      <Amistad_DatosContrato></Amistad_DatosContrato>
      <div className="pagoMens-container">
        <div className="seleccionarPago-inputs">
          <div className="seleccionarPago-inp">
            <label htmlFor="nombre">Servicio</label>
            <input type="text" id="nombre" value={window.localStorage.servicio}/>
          </div>
        </div>
        <div className="pagoMens-seleccione">
          <h3>SELECCIONE LAS MENSUALIDADES A PAGAR</h3>
          
          <div className="PagoMens-secc1">
            {/* <div className="pagoMens-imgtext">
              <img src={ImgSelecc} alt="Amistad seleccione pago" />
              <h3>SELECCIONE LAS MENSUALIDADES A PAGAR</h3>
            </div> */}
          </div>
          <div className="PagoMens-secc2">
            <table>
              <thead>
                <tr>
                  <th>PAGO</th>
                  <th>FECHA DE PAGO</th>
                  <th>MONTO</th>
                  <th>MORATORIO</th>
                  <th>TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {
                  state.registros.map((e,i) => 
                  <tr key={i} className={e.seleccionado == true ? "seleccionado" : ""} onClick={ev=>seleccionar(e)}>
                      <td>{e.nuMensualidad}</td>
                      <td>{date_fns.format(date_fns.parseISO(e.fechaIndicada), "dd/MM/yyyy")}</td>
                      <td>{formatMoney(e.cantidad, 0, ".", ",")}</td>
                      <td>{formatMoney(e.recargo, 0, ".", ",")}</td>
                      <td>{formatMoney(e.cantidad + e.recargo, 0, ".", ",")} </td>
                  </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
        <div className="PagoMensTotal-container">
        <div className="PagoMens-total">
          <div className="PagoMens-totalPartes">
          <table>
            <tr>
              <td>SUBTOTAL</td>
              <td>{formatMoney(state.subtotal, 2, ".", ",")}</td>
              <td>RECARGO</td>
              <td>{formatMoney(state.recargos, 2, ".", ",")}</td>
            </tr>
            <tr>
              <td colSpan="3" style={{backgroundColor:"#f0840b", color:"#fff", letterSpacing:"0.2em"}}>TOTAL A PAGAR</td>
              <td>{formatMoney(state.total, 2, ".", ",")}</td>
            </tr>
          </table>
          </div>
        </div>
        </div>
        <div className="seleccionarPago-prevnext ">
        <Link to="/seleccionar-pago"><div className="seleccionarPago-prevnext-btn diff">Regresar</div></Link>
          <Link to="/bienvenida"><div className="seleccionarPago-prevnext-btn diff">Cancelar</div></Link>
          <Link><div className="seleccionarPago-prevnext-btn diff" onClick={realizarPago}><i class="far fa-credit-card"></i>Pagar</div></Link>
        </div>
        {/* <div className="PagoMens-btnsA">
          <div className="seleccionarPago-btnCan2">
            <Link to="/bienvenida">
              <button>Cancelar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnCan2 dos">
            <Link to="/seleccionar-pago">
              <button>Regresar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnAcep" onClick={realizarPago}>
            <button>Pagar</button>
          </div>
        </div> */}
      </div>
      <Amistad_Footer></Amistad_Footer>
    </div>
  );
}

export default Pago_Servicios;
