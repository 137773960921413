import React from "react";
import { useState } from "react";
import './reportar-deposito.css';


const ReportarDeposito = () => {
   const [banco, setBanco] = useState("banamex");
   function rango(desde, hasta){
      var ret = [];
      for (let i = desde; i < hasta+1; i++) {
         ret.push(i);
      }
      return ret;
   }
   
   return (
         <div class="reportarDep-contenido">
            <div className="reportarDep-titulo">Reportar Depósito</div>
            <div className="reportarDep-subtitulo">
                     Elige tu banco
                  </div>
               <div className="reportarDep-metodos-field">
                  <div className={"reportarDep-metodo " + (banco == "banamex" ? "activo" : "")} onClick={(e=>{setBanco("banamex");})} >Banamex</div>
                  <div className={"reportarDep-metodo " + (banco == "santander" ? "activo" : "")}  onClick={(e=>{setBanco("santander");})}>Santander</div>
               </div>
               <div className="reportarDep-datos">
                  <div className="reportarDep-inputs">
                  <div className="reportarDep-fecha-titulo">
                           Fecha del depósito
                        </div>
                     <div class="reportarDep-fecha">
                        <select  placeholder="Día"> 
                           <option>Día</option>
                           {rango(1,31).map(dia=><option>{dia}</option>)}
                        </select>
                        
                        <select  placeholder="Mes">
                           <option>Mes</option>
                           {rango(1,12).map(dia=><option>{dia}</option>)}
                        </select>
                        
                        <select  placeholder="Año">
                           {rango(2021,2040).map(dia=><option>{dia}</option>)}
                        </select>
                     </div>
                     <input type="text" placeholder={banco == "banamex" ? "Autorización" : "Movimiento"}/>
                     <input type="text" placeholder="Importe"/>
                  </div>
               </div>
               <div className="reportarDep-boton-popup">
                  Reportar
               </div>
            </div>
   )
}

export default ReportarDeposito;