import React, { useEffect, useRef, useState } from 'react';
// import './Preguntas_frecuentes.css';
import './Preguntas_frecuentes.scss';
import { Link } from "react-router-dom";

import Tel from '../res/faq/tel.png';
import Tel2 from '../res/faq/tel2.png';
import Tel3 from '../res/faq/tel3.png';
import Tel4 from '../res/faq/tel4.png';
import Asesor from '../res/faq/asesores/angel.png';
import LogoWhats from '../res/faq/waImg.png';
import ImgSantander from "../res/web/logo_santander.png";
import Amistad_DatosContrato from './Amistad_DatosContrato';
import { formatMoney, ObtenerEstadoCuenta, ObtenerFaqData, validar } from '../back_helper';
import Swal from "sweetalert2";
import EstadoCuenta from '../reportes/EstadoCuenta';
import PopupCitaAgente from './PopupCitaAgente';

window.preguntas_ = [];

function SubPreguntaExpandible({datos, ajustarTamaño}) {
   var ref = useRef();
   var [defaultHeight, setDefaultHeight] = useState(null);
   var [expandido, setExpandido] = useState(false);
   var [estilo, setEstilo] = useState({display: "none"});

   useEffect(e=> {
      // window.preguntas_.push(ref);
      if(!ref.current) return;
      setDefaultHeight(ref.current.offsetHeight);
   }, [datos]);

   useEffect(e=>{
      // console.log("recalcular Expandido", defaultHeight, expandido, estilo);
      // if(!defaultHeight) return;

      if(expandido){
         // console.log("cambiando estilo");
         // setEstilo({height: defaultHeight + "px"});
         // setEstilo({maxHeight: (defaultHeight + 200) + "px"});
         setEstilo({display: "block"});
      }
      else{
         // console.log("desexpandiendo");
         // setEstilo({maxHeight: "0px"});
         setEstilo({display: "none"});
      }
      
      if(ajustarTamaño)
      {
         ajustarTamaño(defaultHeight * (expandido ? 1 : -1));
      }
   },[expandido, defaultHeight]);

   if(!datos) return null;
   return <div>
      <li className="subtitulo" onClick={e=>setExpandido(!expandido)}>
         <span>{datos.subtitulo}</span>
      </li>
      <div className='contenido-expandible' ref={ref}  style={estilo}>
         <div>{datos.contenido}</div>         
      </div>
   </div>
}

function PreguntaExpandible({datos}) {
   var ref = useRef();
   var [defaultHeight, setDefaultHeight] = useState(null);
   var [expandido, setExpandido] = useState(false);
   var [estilo, setEstilo] = useState({});

   var ajustarTamaño = (tamaño) => {
      // console.log("ajustarCambioHijos", exp, defaultHeight, tamaño);
      defaultHeight += tamaño;
      setDefaultHeight(defaultHeight);
   }   

   useEffect(e=> {
      // window.preguntas_.push(ref);
      console.log("DEFAULT HEIGHT", ref.current.offsetHeight);
      if(!ref.current) return;
      setDefaultHeight(ref.current.offsetHeight + 48);
   }, [datos]);

   useEffect(e=>{
      // console.log("recalcular Expandido", defaultHeight, expandido, estilo);
      if(!defaultHeight) return;

      if(expandido){
         // console.log("cambiando estilo");
         // setEstilo({height: defaultHeight + "px"});
         // setEstilo({maxHeight: (defaultHeight + 250) + "px"});
         setEstilo({display: "block"});
      }
      else{
         // console.log("desexpandiendo");
         setEstilo({display: "none"});
         // setEstilo({maxHeight: "0px"});
      }
   },[expandido, defaultHeight]);
   
   if(!datos) return null;

   return <div className="faq-content-container">
      
      <div className="faq-box" onClick={e=>setExpandido(!expandido)}>
         <li>{datos.titulo}</li>
         <i className={expandido ? "fa fa-arrow-circle-up" : "fa fa-arrow-circle-down"}></i>
      </div>

      <div ref={ref}  style={estilo} className="contenido-expandible">
         <div>
            {datos.header}
            {datos.contenido?.constructor == Array ?
            <ol className="sublista">
               
               {datos.contenido.map(f=>
                  <SubPreguntaExpandible datos={f} ajustarTamaño={ajustarTamaño}/>
               )}
            </ol>
            : datos.contenido
            }
            {datos.footer}
         </div>
      </div>
   </div>;
}


function Preguntas_frecuentes() {

   // const AsesorNombre = [
   //    {
   //       nombre: "Angel Sandoval"
   //    },
   //    // {
   //    //    nombre: "Violet"
   //    // },
   //    // {
   //    //    nombre: "Chatita"
   //    // }
   // ]
   

   var [contacto, setContacto] = useState({});
   var [pagosPendientes, setPagosPendientes] = useState([]);
   var [datos, setDatos] = useState({});
   var [popupCitaAbierto, setPopupCitaAbierto] = useState(false);

   const inicializarDatos = async () => {
      var data = await ObtenerFaqData(window.localStorage.contrato);
      console.log("datos del contrato para el FAQ", data);
      if(data.error) return;
      window.datos = data;
      setPagosPendientes(data[0]);
      setDatos(data[1][0]);
   }

   useEffect(e=>{
      inicializarDatos();
   }, []);

   var imprimiendo = false;

   const ImprimirEstadoCuenta = async () => {
     if(imprimiendo) return;
     imprimiendo = true;
     var data = await ObtenerEstadoCuenta(window.localStorage.contrato);
     setEstadoCuentaData(data);
     imprimiendo = false;
   }

  var procesando = false;
  const enviarContacto = async () =>{
    validar(contacto.nombre, "Debes escribir tu nombre");
    validar(contacto.apellido, "Debe escribir tu apellido");
    validar(contacto.correo, "Debes escribir tu correo");
    validar(contacto.telefono, "Debes escribir tu telefono");
    //  validar(contacto.estado, "Debes seleccionar un estado");
    //  validar(contacto.proyecto, "Debes escribir el nombre del desarrollo");

    if(procesando) return;
    procesando = true;
    console.log("Enviando contacto", contacto);
    var res;
    try {
      res = await fetch("/back/contacto", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(contacto)
      });
      res = await res.json();
    } catch (error) {
      res = {ok:false};
    }

    if(res.ok)
    {
      Swal.fire({
        "title" : "En un momento un miembro de nuestro equipo lo contactará",
        "icon" : 'success'
      });
    }
    else {
      Swal.fire({
        "title" : "ups, parece que ha habido un error, te recomendamos que nos contactes a cualquiera de nuestros números",
        "icon" : 'warning'
      });
    }
    procesando = false;
  }


   var faq =
      [
         {
            titulo: "Adeudo actual",
            contenido: 
            <div className="faq-boxes">
               {  pagosPendientes.length == 0 ?
                  <>
                     <p>Mensualidad: <span className="coloured-blue normal"></span></p>
                     <p>Equipamiento: <span className="coloured-blue normal"></span></p>
                     <p>Electrificación: <span className="coloured-blue normal"></span></p>
                     <p>Mantenimiento: <span className="coloured-blue normal"></span></p>
                     <p>Mantenimiento: <span className="coloured-blue normal"></span></p>
                     <p>Mantenimiento: <span className="coloured-blue normal"></span></p>
                     <p>Mantenimiento: <span className="coloured-blue normal"></span></p>
                     <p>Mantenimiento: <span className="coloured-blue normal"></span></p>
                  </>
                  :
                  pagosPendientes.map(e=>
                     <p>{e.concepto}: <span className="coloured-blue normal">{formatMoney(e.cantidad, 2)}</span></p>
                  )
               }
               
               {/* <p>Equipamiento: <span className="coloured-blue normal">$2,000</span></p>
               <p>Electrificación: <span className="coloured-blue normal">$600</span></p>
               <p>Mantenimiento: <span className="coloured-blue normal">$350</span></p> */}
            </div>
         },
         {
            titulo: "Requisitos cambio Propietario",
            contenido:
            [
               {
                  subtitulo: "Nuevo propietario",
                  header: "REQUISITOS",
                  contenido:
                  <div>
                     <ol className="subSubList">
                        <li>INE vigente del nuevo titular.</li>
                        <li>Lugar y fecha de nacimiento.</li>
                        <li>Estado Civil.</li>
                        <li>Correo electrónico.</li>
                        <li>Poporcionar una tarjeta de debito o crédito para realizar su pago mensual domiciliado.</li>
                        <li>Ingreso mensual aproximado, ocupación y empresa en donde labora.</li>
                        <li>INE del beneficiario, parentesco, dirección y teléfono.</li>
                        <li>Comprobante de domicilio (No mayor a 3 meses).</li>
                        <li>2 Referencias (Nombre, domicilio que no sea el mismo del nuevo propietario ni el beneficiario y número de teléfono vigente.</li>
                     </ol>
                  </div>
               },
               {
                  subtitulo: "Titular Anterior",
                  contenido:
                  <div>
                     <ol className="subSubList">
                        <li>INE vigente del titular actual</li>
                        <li>Papeleria del Terreno (Contratos, Calendario de Pagos, Tarjeta de Pagos, Etc)</li>
                        <li>Estar al día en sus mensualidades y todos los conceptos</li>
                        <li>El trámite consta de una cita presencial aproximadamente de hora y media</li>
                     </ol>
                  </div>
               },
               {
                  subtitulo: "Costo del trámite",
                  contenido:
                  <div className="faq-boxes lessWeight">
                     <p>El costo es de dos mensualidad a precio actual del Terreno, las cuales no se abonan al calendario actual.
                     <strong>*EL PAGO TENDRÁ QUE SER REALIZADO ANTES DE LA FECHA DE TU CITA</strong>
                     <h6>Costo del trámite: <span>{formatMoney(datos.mensListaActual * 2, 0)}</span></h6>
                     </p>
                  </div>
               },
               {
                  subtitulo: "Cita para el trámite",
                  contenido:
                  <div className="faq-boxes lessWeight">
                     <p>El trámite se realiza únicamente bajo cita contando con la presencia de los interesados. El nuevo titular firmará contrato con las clausulas vigentes a la fecha del trámite las cuales no afectan en el precio contratado.
                     </p>
                  </div>
               },
            ],
            footer: 
            <div>
               <p className="coloured-red normal">Nota: Costo meramente informativo, para realizar su trámite es necesario comunicarse a nuestro departamento de Atención a clientes, agendar cita y solicitar costo del trámite</p>
               <div className="btn-generico"><Link to="/cita-datos" target="_blank"><h1>GENERAR CITA</h1></Link> </div>
            </div>
         },
         datos.sucursal == "monclova" ?
         {
            titulo: "Escrituración",
            contenido: 
            <div className="faq-boxes lessWeight">
               <p>
                  Al momento de finiquitar, el cliente debe solicitar una carta instrucción para escrituración (la primer carta instrucción no tiene costo).
                  La carta instrucción tiene una vigencia de  90 días,   para que el cliente pueda realizar la escrituración con la dependencia o Notaría de su preferencia. 
                  Si el cliente no concluye con el tramite de escrituración dentro del tiempo otorgado y requiere de una carta instrucción actualizada, la emisión del nuevo documento tendrá un costo del 5% del precio actual del terreno al momento de su solicitud y trámite de su carta instrucción
               </p>
            </div>
         }
         :
         {
            titulo: "Cesión de derechos",
            contenido: [
               {
                  subtitulo: "¿Cuando debo de pagar?",
                  contenido: 
                  <div className="faq-boxes lessWeight">
                     <p>Una vez realizado el último pago de tu terreno cuentas con <span className="coloured-red normal">90 días naturales, </span> para realizar tu pago.</p>
                     <span className="coloured-blue big center">{datos.ultimaFecha}</span>
                  </div>
               },
               {
                  subtitulo: "¿Cuanto debo pagar?",
                  contenido: 
                  <div className="faq-boxes lessWeight">
                     <p>Si tu lote es de alguno de los siguientes fraccionamientos: Milpa, Terramilpa y Huertas tiene un costo fijo de: <strong className="coloured-blue">{formatMoney(datos.sesionDerechos, 0)}</strong></p>                     
                     <p>De lo contrario, el costo de tu cesión depende de los metros cuadrados de la superficie de tu terreno</p>
                     <span>{formatMoney(datos.superficie,2,".", ",", "")}m²- <strong className="coloured-blue">{formatMoney(datos.sesionDerechos, 0)}</strong></span>
                     <p>Comunícate con nosotros para conocer el estatus de tu trámite de cesión de derechos al Telefono de oficina <span className="coloured-blue normal">998 267 9241 </span> o envía un WHATSAPP</p>
                     <a href="https://api.whatsapp.com/send?phone=5219981045826" target="_blank">
                        <img className="whatsImg" src={Tel} alt="img telefono amistad whatsApp" />
                     </a>
                  </div>
               }
            ],
            oculto : /ALAMOS|MEXICO/.test(datos.fraccionamiento)
         },
         {
            titulo: "Números de Cuenta",
            contenido: 
            <div className="faq-boxes lessWeight">
               <p>En Amistad te apoyamos y queremos estár mas cerca de ti desde la comodidad de tu celular</p>
               <img className="santan" src={ImgSantander} alt="santander img" />
               <h3>CONCEPTO: </h3>
               <span className="coloured-blue big">Tu clave de contrato: {window.localStorage.contrato}</span>
               <h3>TITULAR </h3>
               <span className="coloured-blue big">AMISTAD BIENES Y SERVICIOS EMPRESARIALES S.A. DE C.V.</span>
               <h3>CTA: </h3>
               {datos.sucursal == "monclova" ?
                  <span className="coloured-blue big">65-50756977-0</span> 
                  :
                  <span className="coloured-blue big">65-50559108-1</span>
               }
               <h3>CLABE: </h3>
               {datos.sucursal == "monclova" ?
                  <span className="coloured-blue big">014068655075697708</span>
                  : 
                  <span className="coloured-blue big">014691655055910814</span>
               }
               <p>Es importante que reportes tu pago al siguiente whatsapp con tu <span className="coloured-blue normal">nombre, fraccionamiento, manzana y lote</span></p>
               {datos.sucursal == "monclova" ?
                  <a href="https://api.whatsapp.com/send?phone=86623137258"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>866 231 37258</h3>                     
                     </div>
                  </a>
                  : 
                  <a href="https://api.whatsapp.com/send?phone=9981514221"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>998 151 4221</h3>                     
                     </div>
                  </a>
               }
            </div>
         },
         {
            titulo: "Domiciliación de Pagos",
            header: 
               datos.alCorriente ? 
               <div className="faq-boxes">
                  <p>Todos tus pagos están al corriente</p>
               </div>
               : !datos.tieneDomiciliado ? 
               <div className="faq-boxes">
                  <p>No tienes configurados tus pagos domiciliados</p>
                  <p>Telefono de oficina <span className="coloured-blue normal">{datos.sucursal == "monclova" ? "866 636 19" : "998 267 9241"}</span> o envía un WHATSAPP</p>
                  {datos.sucursal == "monclova" ?
                     <a href="https://api.whatsapp.com/send?phone=8661336927"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>866 133 6927</h3>                     
                     </div>
                     </a>
                     :
                     <a href="https://api.whatsapp.com/send?phone=9981514221"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>998 151 4221</h3>                     
                     </div>
                  </a>
                  }
               </div>
               :
               <div className="faq-boxes">
                  <p>Tengo mis pagos domiciliados y aún no se realiza mi cobro</p>
                  <h3>Lote Domiciliado: <span className="coloured-blue big">{window.localStorage.contrato}</span></h3>
                  <h3>Último cobro realizado: <span className="coloured-blue big">{datos.fechaDomic}</span></h3>
                  <h3>Estatus: <span className="coloured-blue big">{datos.estatusDomic}</span></h3>
                  <h3>Motivo: <span className="coloured-blue big">{datos.motivoDomic}</span></h3>
                  {/* <div className="btn-generico"><h1>ACTUALIZA TUS DATOS</h1></div> */}
                  <p>Telefono de oficina <span className="coloured-blue normal">998 267 9241</span> o envía un WHATSAPP</p>
                  <img className="whatsImg" src={Tel2} alt="img whatsApp amistad" />
               </div>,
            contenido: [
               // {
               //    subtitulo: "Quiero domiciliar mis pagos",
               //    contenido: 
               //    <div className="faq-boxes domici">
               //       <div className="form-Domiciliar">
               //          <div>
               //             <h3>CONTRATO</h3>
               //             <input type="text" />
               //          </div>
               //          <div>
               //             <h3>TARJETA</h3>
               //             <input type="text" />
               //          </div>
               //          <div>
               //             <h3>TITULAR</h3>
               //             <input type="text" />
               //          </div>
               //          <div>
               //             <h3>BANCO</h3>
               //             <input type="text" />
               //          </div>
               //          <div>
               //             <h3>TOKEN</h3>
               //             <input type="text" />
               //          </div>
               //          <div>
               //             <h3>E-MAIL</h3>
               //             <input type="text" />
               //          </div>
               //       </div>
               //       <div className="checks">
               //          <div className="check">
               //             <h3>Activar Mensualidad</h3>
               //             <input type="checkbox" />
               //          </div>
               //          <div className="check">
               //             <h3>Activar Servicios</h3>
               //             <input type="checkbox" />
               //          </div>
               //       </div>
               //       <div className="btnsForm">
               //          <div className="btn-generico"><h1>AGREGAR CUENTA</h1></div>
               //          <div className="btn-generico"><h1>EDICION</h1></div>
               //          <div className="btn-generico"><h1>IMPRIMIR AUTORIZACION</h1></div>
               //       </div>
               //    </div>
               // }
            ]
         },
         {
            titulo: "Mi día de pago",
            contenido:
            <div className="faq-boxes lessWeight">
               <p>Mi siguiente fecha de pago es: </p>
               <span className="coloured-blue big">{datos.proximaFecha}</span>
               <p>Puedes consultar tus fechas de pago en tu calendario <Link className="coloured-blue normal" to="/pago-mensualidades" target="_blank">aqui</Link></p>
            </div>
         },
         {
            titulo: "Estado de cuenta",
            contenido: 
            <div className="faq-boxes lessWeight">
               <p>Puede consultar su estado de Cuenta <Link className="coloured-blue normal" onClick={ImprimirEstadoCuenta}>aqui</Link></p>
            </div>
         },
         {
            titulo: "Finiquitar el día de hoy",
            contenido: 
            <div className="faq-boxes lessWeight">
               <p>El monto pendiente para finiquitar su terreno es: </p>
               <p className="strong">{datos.mensualidadesPendientes} Pagos pendientes, mensualidad de {formatMoney(datos.mensualidad, 0)}, pendiente de pago: </p>
               <span className="coloured-blue big">{formatMoney(datos.mensualidadestotal, 0)}</span>
               <p className="strong">Descuento vigente {formatMoney(datos.descuento*100, 0)}%. Monto para finiquitar</p>
               <span className="coloured-blue big">{formatMoney(datos.mensualidadestotal * (1 - datos.descuento), 0)}</span>
               <p className="strong">Vigencia de esta cotización:</p>
               <span className="coloured-blue big">{datos.proximaFecha}</span>

               <p>Consulta tu estado de cuenta <Link className="coloured-blue normal" onClick={ImprimirEstadoCuenta}>aqui</Link></p>
               <p>Puedes realizar tu pago <Link className="coloured-blue normal" to="/pago-capital?finiquitar=1" target="_blank">aqui</Link></p>
            </div>
         },
         {
            titulo: "Ubicar mi lote",
            contenido: 
            <div className="faq-boxes">
               {/* <p>Da clic <Link className="coloured-blue normal" to="#">aqui</Link> para ir a la ubicación</p>
               <div className="mapa">mapa</div> */}
               <div className="agenda-cita">
                  <p>Agenda cita con tu Asesor</p>
                  <img className='imgAsesor' src={"https://amistad.z22.web.core.windows.net/asesores/" + datos.nombreAsesor + ".png"} alt="img asesor amistad bienes raices" />
                  <p>Asesor: </p>
                  <p className="coloured-blue normal uppercase">{datos.nombreAsesor}</p>
                  <div className="btn-generico"> <Link onClick={e=>setPopupCitaAbierto(true)}><h1>AGENDA UNA CITA</h1></Link></div>
                  <div>-</div>
                  {/* {AsesorNombre.map(e=>
                     <p className="coloured-blue normal uppercase">{e.nombre}</p>
                  )} */}
                  {/* <img className="whatsImg" src={Tel2} alt="img asesor whatsApp" /> */}
                  {datos.sucursal == "monclova" ?
                     <a href="https://api.whatsapp.com/send?phone=8661336927"  style={{"textDecoration": "none"}} target="_blank">
                        <div className="whatsAppAse">
                           <img src={LogoWhats} alt="img logo whatsApp" />
                           <h3>866 133 6927</h3>                     
                        </div>
                     </a>
                     :
                     <a href="https://api.whatsapp.com/send?phone=9981000037"  style={{"textDecoration": "none"}} target="_blank">
                        <div className="whatsAppAse">
                           <img src={LogoWhats} alt="img logo whatsApp" />
                           <h3>998 100 0037</h3>                     
                        </div>
                     </a>
                  }
                  <p className="coloured-red normal">
                     Nota: la visita de ubicación puede generar un costo simbólico
                  </p>
               </div>
            </div>
         },
         {
            titulo: "Descuento Pago a Capital",
            contenido: 
            <div className="faq-boxes">
               <p>Descuento vigente: <span className="coloured-blue">{formatMoney(datos.descuento*100, 0)}%</span></p>
               <p>Tus mensualidades son de: <span className="coloured-blue">{formatMoney(datos.mensualidad, 0)}</span></p>
               <p>Tus mensualidades con descuento quedan en: <span className="coloured-blue">{formatMoney(datos.mensualidad * (1-datos.descuento), 0)}</span></p>
               <p className="medium">Este descuento estará vigente hasta el día {datos.fechaVigenciaDescuento}</p>
            </div>
         },
         {
            titulo: "Perdí mis puntos topográficos",
            contenido: 
            <div className="faq-boxes width">
               <p>En Amistad Bienes Raíces contamos con un servicio post-venta de topografía con un costo de $2,000 que incluye: </p>
               <ul className="dot">
                  <li>• Colocación y rótulo de puntos topográficos</li>
                  <li>• Plano con cuadro de Construcción</li>
                  <li>• Archivo .kmz con ubicación del lote</li>
               </ul>
               <p>El tiempo de entrega es de 30 días, es necesario solicitar <Link className="coloured-blue normal" to="/cita-datos?topo=1" target="_blank">aquí</Link> para que se agende su topografía y se genere su liga de pago.</p>
            </div>
         },
         {
            titulo: "Horarios de Atención",
            contenido: [
               {
                  subtitulo: "Administrativo",
                  contenido: 
                  <div className="faq-boxes">
                     {datos.sucursal == "monclova" ?
                        <ul className="dot">
                           <li>• Presencial, llamadas y mensajes de Lunes a Sábado de 9:00 AM a 7:00 PM</li>
                        </ul> 
                        :
                        <ul className="dot">
                           <li>• Presencial de Lunes a Viernes de 9:00 AM a 3:00 PM</li>
                           <li>• Por llamada y mensaje de WHATSAPP de Lunes a Viernes de 9:00 AM a 5:00 PM y sábados de 9:00 a.m. a 2:00 p.m.</li>
                        </ul> 
                     }
                  </div>
               },
               {
                  subtitulo: "Ventas",
                  contenido: 
                  <div className="faq-boxes">
                     <ul className="dot">
                        <li>• Comunícate por llamada o mensaje de WHATSAPP al {datos.sucursal == "monclova" ? "866 209 5743" : "998 386 0033"} o directamente con tu Asesor de Ventas </li>
                        {datos.sucursal == "monclova" ? 
                           <li>• Por llamada y mensaje de WHATSAPP de Lunes a Sábado de 9:00 AM a 7:00 PM</li> 
                           :
                           <li>• Por llamada y mensaje de WHATSAPP de Lunes a Viernes de 9:00 AM a 5:00 PM sábados y domingos de 9:00 AM a 3:00 PM</li> 
                        }
                     </ul>
                     {/* <p className="coloured-blue uppercase center">ANGEL SANDOVAL, 998 151 42 51</p> */}
                     <div className="btn-generico"> <Link to="/cita-datos" target="_blank"><h1>AGENDA UNA CITA</h1></Link></div>
                     <p className="coloured-red normal">Importante: Recuerda que todas las atenciones presenciales se realizan con cita previa, puedes agendar tu cita 
                        <Link className="coloured-blue normal" to="/cita-datos" target="_blank"> aquí</Link>, recuerda que si los horarios no se encuentran visibles es porque no contamos con horarios disponibles para
                        atención
                     </p>
                  </div>
               }
            ]
         },
         {
            titulo: "Cajero fuera de servicio",
            contenido:
            <div className="faq-boxes">
               <p>Recuerda que puedes realizar tu pago en línea en nuestro: </p>
               <div className="btn-generico"><Link to="/pago-mensualidades" target="_blank"><h1>PORTAL DE PAGOS</h1></Link></div>
            </div> 
            
         },
         {
            titulo: "No puedo continuar pagando",
            contenido: 
            <div className="faq-boxes">
               <p>Para Amistad Bienes Raíces lo más importante es apoyarte a continuar con la meta que has iniciado para invertir con nosotros. Si presentas problemas para continuar con tus pagos puedes comunicarte con nosotros y buscaremos la mejor solución para apoyarte: </p>
               <p>Atención a Clientes Oficina {datos.sucursal == "monclova" ? "866 636 1930, Ext 1" : "998 267 9241, Ext 115"} o agenda tu cita por WHATSAPP</p>
               {datos.sucursal == "monclova" ?
                  <a href="https://api.whatsapp.com/send?phone=8661336927"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>866 133 6927</h3>                     
                     </div>
                  </a>
                  :
                  <a href="https://api.whatsapp.com/send?phone=9981166637"  style={{"textDecoration": "none"}} target="_blank">
                     <div className="whatsAppAse">
                        <img src={LogoWhats} alt="img logo whatsApp" />
                        <h3>998 116 6637</h3>                     
                     </div>
                  </a>   
               }
               
            </div>
         },
         // {
         //    titulo: "Dejé de pagar mi Lote",
         //    contenido: 
         //    <div className="faq-boxes">
         //       <p>Es importante mantener al día tus pagos para evitar que se generen moratorios en tus pagos, puedes consultar 
         //       cotización actualizada de tus pagos <Link className="coloured-blue normal" to="/pago-mensualidades#">aquí.</Link>
         //       </p>
         //    </div>
         // },
         {
            titulo: "Adquirir otro Lote",
            contenido: 
            <div className="faq-boxes">
               <p>Nuestro departamento de Ventas siempre esta disponible para poder apoyarte, comunícate con nosotros al tel de Oficina {datos.sucursal == "monclova" ? "866 636 1930" : "998 267     9241"} o al WHATSAPP 
                  {datos.sucursal == "monclova" ? 
                     <a href="https://api.whatsapp.com/send?phone=8662095743" style={{"textDecoration": "none"}} target="_blank">
                        <div className="whatsAppAse">
                           <img src={LogoWhats} alt="img logo whatsApp" />
                           <h3>866 209 5743</h3>
                        </div>
                     </a>
                     : 
                     <a href="https://api.whatsapp.com/send?phone=5219983860033 " style={{"textDecoration": "none"}} target="_blank">
                        <div className="whatsAppAse">
                           <img src={LogoWhats} alt="img logo whatsApp" />
                           <h3>998 386 0033</h3>
                        </div>
                     </a>
                  }
                  con gusto se te asignará un asesor.
                  </p>
                  <p>Si lo prefieres puedes dejarnos tus datos para que un asesor se comunique contigo.</p>
                  <div className="otroLote-form">
                     <div>
                        <h3>Nombre: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, nombre: e.target.value})}/>
                     </div>
                     <div>
                        <h3>Apellido: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, apellido: e.target.value})}/>
                     </div>
                     <div>
                        <h3>Número telefónico: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, telefono: e.target.value})}/>
                     </div>
                     <div>
                        <h3>Correo: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, correo: e.target.value})}/>
                     </div>
                     <div>
                        <h3>Horario en que puedes atender llamadas: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, mensaje: "me gustaría recibir la llamada en este horario: " +  e.target.value})}/>
                     </div>
                     <div>
                        <h3>Desarrollo que le interesa: </h3>
                        <input type="text" onChange={e=>setContacto({...contacto, proyecto: e.target.value})}/>
                     </div>
                  </div>
                  <div>
                     <div className='btn-generico' onClick={enviarContacto}>
                        <h1>Enviar</h1> 
                     </div>
                  </div>
            </div>
         },
         // {
         //    titulo: "Acuerdo de pagos",
         //    contenido: 
         //    <div className="faq-boxes">
         //       <p>Es importante mantener al día tus pagos para evitar que se generen moratorios en tus pagos, puedes consultar tu 
         //          estado de cuenta <Link className="coloured-blue normal" onClick={ImprimirEstadoCuenta}>aquí</Link> o generar una cita con nuestro departamento de Atención a Clientes</p>
         //       <p className="coloured-red normal">Importante: Recuerda que todas las atenciones presenciales se realizan con cita previa, puedes agendar tu cita 
         //       <Link className="coloured-blue normal" to="/cita-datos"> aquí, </Link> recuerda que si los horarios no se encuentran visibles es porque no contamos con horarios disponibles para atención</p>
         //    </div>
         // },
         {
            titulo: "Quiero Recomendar",
            contenido: 
            <div className="faq-boxes">
               <p>¿Estás listo para <span className="coloured-blue uppercase"> GANAR</span> dinero de manera <span className="coloured-blue normal"> rápida y fácil?</span></p>
               <p>Podrás agregar a todos los prospectos que quieras y en cuanto firmen su contrato <span className="coloured-blue uppercase"> ¡YA GANASTE!</span></p>
               <p>Registrate ahora <a href="http://ryg.amistadbr.com/login" target="_blank">http://ryg.amistadbr.com/login</a></p>
               <span className="coloured-blue normal">Recuerda, entre más recomiendas, más ganas.</span>
               <span className="coloured-blue normal">¡Disponible las 24 horas del día para ti!</span>
            </div>
         }
      ]

   var [estadoCuentaData, setEstadoCuentaData] = useState(null);
   useEffect(e=>{
      window.faq_ = faq;
   }, []);
   

   var res = faq.length;
   console.log("Hay " + res  + " Preguntas en total");

  return (
    <React.Fragment>
      <Amistad_DatosContrato></Amistad_DatosContrato>
      <div className="faq-container">
         <div className="faq-title">
            {/* <i className="fa fa-arrow-circle-down"></i> */}
            <i className="fa fa-question-circle"></i>
            <h1>PREGUNTAS FRECUENTES </h1>
         </div>
         <ol className="faq-content">
            {faq.map((e, i)=>
               e.oculto ? "" :
               <PreguntaExpandible datos={e} key={i + "-pregunta"}/>
            )}
         </ol>
      </div>
      
      {
         estadoCuentaData == null ? "" :
         <div className="impresion-fondo">
            <EstadoCuenta data={estadoCuentaData} onComplete={()=>setEstadoCuentaData(null)}>

            </EstadoCuenta>
         </div>
      }
      
      {!datos?.idAsesor ? "" : 
         <PopupCitaAgente abiertoEstado={[popupCitaAbierto, setPopupCitaAbierto]} datos={{...datos}}/>
      }
    </React.Fragment>
  );
}

export default Preguntas_frecuentes;