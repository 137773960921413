import React from 'react';
import './Gracias_Pago.css';
import Amistad_Logos from './Amistad_Logos';
import { Link } from 'react-router-dom';
import { useLocation } from "react-router";
import Amistad_Footer from './Amistad_Footer';
import {Logo2} from "./Amistad_Logos.jsx";

const LogoAmistadC = Logo2;

function Gracias_Pago() {
  const location = useLocation();
  const { search, pathname } = location;
  const query = new URLSearchParams(search);

  var success = query.get("success");
  var nbResponse = query.get("nbResponse");

   return (
      <div className="graciasPago-main amistadFondo invertido">
         <div className="graciasPago-logoC">
           <img src={LogoAmistadC} alt="iamgen amistad logo" />
         </div>
         <div className="graciasPago-content">
           { nbResponse == "Aprobado" ? 
             <h1 className="graciasPago-h1 success">GRACIAS <br /> <span>por realizar su pago</span></h1>
            :
            <h1 className="graciasPago-h1">LO SENTIMOS,<br/>SU PAGO FUE DECLINADO</h1>
           }
            <div className="PagoMens-btnsFin">
          <div className="seleccionarPago-btnOtra">
            
           { nbResponse == "Aprobado" ? 
              <Link to="/seleccionar-pago">
                <button>Realizar otro pago</button>
              </Link>
              :
              <Link to={localStorage.ultimo_checkout}>
                <button>Regresar</button>
              </Link>
            }
          </div>
          <div className="seleccionarPago-btnListo">
            <Link to="/bienvenida">
              <button>Finalizar</button>
            </Link>
          </div>
        </div>
         </div>
         <Amistad_Footer></Amistad_Footer>
      </div>
   )
}

export default Gracias_Pago;
