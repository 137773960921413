import "./App.css";
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Portal from './components/portal';
import FormaPago from './components/forma-pago';
import ConfirmacionTransaccion from "./components/confirmacion-transaccion";
import Bienvenida from './components/Bienvenida';
import Seleccionar_Pago from "./components/Seleccionar_Pago";
import Pago_Mensualidades from "./components/Pago_Mensualidades";
import Pago_Capital from "./components/Pago_Capital";
import Pago_Servicios from "./components/Pago_Servicios";
import Gracias_Pago from './components/Gracias_Pago';
import Amistad_Footer from "./components/Amistad_Footer";
import Seleccione_Servicio from "./components/Seleccione_Servicio";
import Amistad_Comprobantes from "./components/Amistad_Comprobantes";
import Bienvenida_asesores from "./components/Bienvenida_asesores";
import Pago_asesores from "./components/Pago_asesores";
import Agendar_cita from './components/Agendar_cita';
import Cita_datos from './components/Cita_datos';
import Preguntas_frecuentes from "./components/Preguntas_frecuentes";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/portal">
            <Portal></Portal>
          </Route>
          <Route path="/transaccionfin">
            <Gracias_Pago></Gracias_Pago>
          </Route>
          <Route path="/gracias-pago">
            <Gracias_Pago></Gracias_Pago>
          </Route>
          <Route path="/forma-pago">
            <FormaPago></FormaPago>
          </Route>
          <Route path="/bienvenida">
            <Bienvenida></Bienvenida>
          </Route>
          <Route path="/bienvenida-asesores">
            <Bienvenida_asesores></Bienvenida_asesores>
          </Route>
          <Route path="/agendar-cita">
            <Agendar_cita></Agendar_cita>
          </Route>
          <Route path="/cita-datos">
            <Cita_datos></Cita_datos>
          </Route>
          <Route path="/seleccionar-pago">
            <Seleccionar_Pago></Seleccionar_Pago>
          </Route>
          <Route path="/preguntas-frecuentes">
            <Preguntas_frecuentes></Preguntas_frecuentes>
          </Route>
          <Route path="/pago-asesores">
            <Pago_asesores></Pago_asesores>
          </Route>
          <Route path="/pago-mensualidades">
            <Pago_Mensualidades></Pago_Mensualidades>
          </Route>
          <Route path="/pago-capital">
            <Pago_Capital></Pago_Capital>
          </Route>
          <Route path="/seleccione-servicio">
            <Seleccione_Servicio></Seleccione_Servicio>
          </Route>
          <Route path="/pago-servicios">
            <Pago_Servicios></Pago_Servicios>
          </Route>
          <Route path="/comprobantes">
            <Amistad_Comprobantes></Amistad_Comprobantes>
          </Route>
          <Route path="/gracias-pago">
            <Gracias_Pago></Gracias_Pago>
          </Route>
          <Route path="/">
            <Bienvenida></Bienvenida>
          </Route>
        </Switch>
      </Router>
      {/* 
        <Amistad_Footer></Amistad_Footer>
      */}
    </div>
  );
}

export default App;
