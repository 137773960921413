import React, { useEffect } from "react";
import Amistad_Logos from "./Amistad_Logos";
import ImgSelecc from "../res/png_iconos/Seleccionar.png";
import { useState } from "react";
import { ObtenerServicios } from "../back_helper";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Amistad_Footer from "./Amistad_Footer";
import Amistad_DatosContrato from "./Amistad_DatosContrato";

function Seleccione_Servicio({ history }) {
  var [registros, setRegistros] = useState([]);

  const cargarServicios = async () => {
    var servicios = await ObtenerServicios(localStorage.contrato);
    registros = servicios;
    seleccionar(registros[0]);
  };

  useEffect(() => {
    cargarServicios();
  }, []);

  const seleccionar = (registro) => {
    // var seleccionado = false;
    for (let i = 0; i < registros.length; i++) {
      const e = registros[i];
      e.seleccionado = false;
      if (e == registro) {
        e.seleccionado = true;
      }
    }
    console.log("")
    setRegistros([...registros]);
  };

  const confirmarServicio = () => {
    var servicio_seleccionado;
    for (let i = 0; i < registros.length; i++) {
      const e = registros[i];
      if (e.seleccionado == true) {
        servicio_seleccionado = e;
        break;
      }
    }
    console.log(registros, servicio_seleccionado);

    window.localStorage.servicio = servicio_seleccionado.servicioExtra;
    history.push("/pago-servicios");
  };

  console.log("registros", registros);

  return (
    <div className="pagoServ-main amistadFondo invertido">
      <Amistad_DatosContrato></Amistad_DatosContrato>
      <div className="pagoMens-container">
        {/* <div className="seleccionarPago-inputs">
          <div className="seleccionarPago-inp">
            <label htmlFor="contrato">Contrato</label>
            <input type="text" id="contrato" value={window.localStorage.contrato} />
          </div>
          <div className="seleccionarPago-inp">
            <label htmlFor="nombre">Nombre</label>
            <input type="text" id="nombre" value={window.localStorage.contrato_nombre}/>
          </div>
        </div> */}
        <div className="pagoMens-seleccione">
          <h3>SELECCIONE EL SERVICIO A PAGAR</h3>
          {/* <div className="PagoMens-secc1">
            { <div className="pagoMens-imgtext">
              <img src={ImgSelecc} alt="Amistad seleccione pago" />
              <h3>SELECCIONE EL SERVICIO A PAGAR</h3>
            </div> 
          </div> */}
          <div className="PagoMens-secc2">
            <table>
              <thead>
                <tr>
                  <th>CONTRATO</th>
                  <th>SERVICIO EXTRA</th>
                </tr>
              </thead>
              <tbody>
                {registros.map((e, i) => (
                  <tr
                    key={i}
                    className={e.seleccionado == true ? "seleccionado" : ""}
                    onClick={(ev) => seleccionar(e)}
                  >
                    <td>{e.contrato}</td>
                    <td>{e.servicioExtra}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="seleccionarPago-prevnext ">
          <Link to="/seleccionar-pago">
            <div className="seleccionarPago-prevnext-btn diff">Regresar</div>
          </Link>
          <Link to="/bienvenida">
            <div className="seleccionarPago-prevnext-btn diff">Cancelar</div>
          </Link>
          <a>
            <div className="seleccionarPago-prevnext-btn diff" onClick={confirmarServicio}>
              Siguiente
            </div>
          </a>
          {/* <Link to="/pago-servicios">
            <div className="seleccionarPago-prevnext-btn diff">Siguiente</div>
          </Link> */}
        </div>
      </div>
      <Amistad_Footer></Amistad_Footer>
    </div>
  );
}

export default withRouter(Seleccione_Servicio);
