import React from 'react';
import './Amistad_Logos.css';
import Logo_Ciudad_Cancun from "../res/web/logo-cancun2.png";
import Logo_Amistad_Cancun from "../res/web/Logo_Cancun.png";
import Logo_Ciudad_Monclova from "../res/web/Logo_monclova2.png";
import Logo_Amistad_Monclova from "../res/web/Logo_monclova.png";
// import Logo26Years from "../res/movil/26years.png";

var monclova = /mva/.test(window.location.hostname) || (localStorage.mva != null);


export var Logo1 = monclova ?  Logo_Ciudad_Monclova : Logo_Ciudad_Cancun;
export var Logo2 = monclova ?  Logo_Amistad_Monclova : Logo_Amistad_Cancun;


// export var Logo3 = Logo26Years;

console.log("Cargando Logos", monclova, Logo1, Logo2);

export var esMonclova = monclova;

export var correo = esMonclova ? "cobranzamva@amistadbienesraices.com" : "cobranza@amistadbienesraices.com";

function Amistad_Logos() {
   return (
      <div className="amistadLogos-main">
         <div className="amistadLogos-imgs">
            <img src={Logo1} alt="" style={{width:"auto", height:"2em", marginLeft:monclova ? "2em" : "8em"}}/>
            <img src={Logo2} alt="" />
            <img src="https://amistad.z22.web.core.windows.net/Logos/27a.png" alt="26 años amistad" />
         </div>
      </div>
   )
}

export default Amistad_Logos;
