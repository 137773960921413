import React, { useEffect, useState, useRef } from "react";
import "./portal.css";
import amistadlogo from "../res/amistadlogo.png";
import Popup from "reactjs-popup";
import ReportarDeposito from "./reportar-deposito";
import ContratoImg from "../res/contrato-imagen.png";
import Swal from "sweetalert2";
import { useLocation, withRouter } from "react-router-dom";
import TerminosCondiciones from "./terminos-condiciones";


function ConfirmacionTransaccion({ history }) {

  const location = useLocation();
  const { search, pathname } = location;
  const query = new URLSearchParams(search);

  var success = query.get("success");
  var nbResponse = query.get("nbResponse");

  //transaccionfin?&success=true&nbResponse=Rechazado&cdResponse=Transaccion+declinada&nb_error=La+tarjeta+no+pudo+ser+autenticada+en+3DS.&sucursal=009&empresa=AMISTAD+BIENES+RAICES&importe=1.00&referencia=PED11L10JUA-7&referenciaPayment=PED11L10JUA-7&nbMoneda=MXN&cdEmpresa=3276&urlTokenId=26UYCESD&idLiga=26UYCESD&email=sacelx%40outlook.com
  return (
    <div className="contenido-main">
      <div className="portal-imagen">
        <img
          src={amistadlogo}
          alt="Banner Amistad"
          width="200px"
          height="150"
        />
      </div>
      <div className="portal-contenido">
        {nbResponse == "Aprobado" ? 
          <div>
            <div className="portal-subtitulo-buscador">El cargo se realizó correctamente</div>
            <div className="portal-texto1">Gracias por su pago</div>
          </div>
        : nbResponse == "Rechazado" ?
          <div>
            <div className="portal-subtitulo-buscador">Su pago fue declinado</div>
            <div className="portal-texto1">Intenta de nuevo</div>
          </div>
        :
          <div>
            <div className="portal-subtitulo-buscador">Ocurrio un error con el pago</div>
            <div className="portal-texto1">Intenta de nuevo</div>
          </div>
        }
        <div className="portal-cuadro-buscador">
          <div className="portal-subtitulo-buscador">
            Para regresar oprime el siguiente botón{" "}
          </div>
          <div className="portal-campo">
            <div className="portal-boton-contrato" onClick={e => history.push("\portal")}>
              Realizar Otra Operación
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default withRouter(ConfirmacionTransaccion);
