import React, { useEffect, useState } from "react";
import "./Seleccionar_Pago.css";
import Amistad_Logos from "./Amistad_Logos";
import Img23 from "../res/png_iconos/Dinero.png";
import Img24 from "../res/png_iconos/intereses.png";
import Img25 from "../res/png_iconos/arbol.png";
import Logo2 from "../res/movil/26years.png";
import personaje from "../res/popup/personaje.png";
import ImgRyG from "../res/btn-ryg.gif";
import { Link } from "react-router-dom";
import Amistad_Footer from "./Amistad_Footer";
import { ObtenerCalendarioPagos, ObtenerEstadoCuenta } from "../back_helper";
import Amistad_DatosContrato from "./Amistad_DatosContrato";
import EstadoCuenta from "../reportes/EstadoCuenta";
import Popup from "reactjs-popup";

function Seleccionar_Pago() {
  var [pagoCapitalHabilitado, setPagoCapitalHabilitado] = useState(false);
  var [estadoCuentaData, setEstadoCuentaData] = useState(null);

  const [open1, setOpen1] = useState(false);
  const CerrarModal1 = () => setOpen1(false);
  const OpenModal1 = () => setOpen1(true);

  const cargarMensualidades = async () => {
    var mensualidades = await ObtenerCalendarioPagos(
      window.localStorage.contrato
    );
    console.log("mensualidades", mensualidades);
    for (let i = 0; i < mensualidades.length; i++) {
      const e = mensualidades[i];
      if (e.descuento) {
        setPagoCapitalHabilitado(true);
        return;
      }
    }
    // setPagoCapitalHabilitado(true);
  };

  var imprimiendo = false;

  const ImprimirEstadoCuenta = async () => {
    if(imprimiendo) return;
    imprimiendo = true;
    var data = await ObtenerEstadoCuenta(window.localStorage.contrato);
    setEstadoCuentaData(data);
    imprimiendo = false;
  }

  useEffect(() => {
    cargarMensualidades();
  }, []);

  console.log("pago capital habilitado", pagoCapitalHabilitado);

  return (
    <div className="seleccionarPago-main amistadFondo invertido">
      <Amistad_DatosContrato></Amistad_DatosContrato>
      <div className="seleccionarPago-container">
        {/* <div className="seleccionarPago-inputs">
          <div className="seleccionarPago-inp">
            <label htmlFor="contrato">Contrato</label>
            <input type="text" id="contrato" value={window.localStorage.contrato} />
          </div>
          <div className="seleccionarPago-inp">
            <label htmlFor="nombre">Nombre</label>
            <input type="text" id="nombre" value={window.localStorage.contrato_nombre}/>
          </div>
        </div> */}
        <div className="seleccionarPago-botones-main">
          <h1>SELECCIONE EL PAGO QUE DESEA REALIZAR</h1>
          <div className="seleccionarPago-botones">
            <a href="https://www.portal.amistadbr.com/" target="_blank"><div className="btn-ryg"><img src={ImgRyG} alt="recomienda y gana boton" width="auto" height="100" /></div></a>
            <Link to="/pago-mensualidades"><div className="seleccionarPago-boton">MENSUALIDADES</div></Link>
            <Link 
            onClick={e=>{
              if(!pagoCapitalHabilitado)
              {
                e.preventDefault();
              }
              else{
                OpenModal1();
              } 
            }}
            ><div className={"seleccionarPago-boton seleccionarPago-btn1" + (!pagoCapitalHabilitado ? " deshabilitado" : "")}>PAGOS A CAPITAL</div></Link>
            <Link to="/seleccione-servicio"><div className="seleccionarPago-boton">PAGO DE SERVICIOS</div></Link>
            <h2>OTRAS OPCIONES</h2>
            <Link onClick={ImprimirEstadoCuenta}><div className="seleccionarPago-boton">ESTADO DE CUENTA</div></Link>
            <Link to="/comprobantes"><div className="seleccionarPago-boton">COMPROBANTES</div></Link>
            <Link to="/cita-datos"><div className="seleccionarPago-boton">ATENCION A CLIENTES</div></Link>
            <Link to="/preguntas-frecuentes"><div className="seleccionarPago-boton">PREGUNTAS FRECUENTES</div></Link>
          </div>
            <div className="seleccionarPago-botones-main">
           
            </div>

        </div>
        {
          estadoCuentaData == null ? "" :
          <div className="impresion-fondo">
              <EstadoCuenta data={estadoCuentaData} onComplete={()=>setEstadoCuentaData(null)}>

              </EstadoCuenta>
          </div>
        }
        {/* <div className="seleccionarPago-hbtn">
          <h1>SELECCIONE EL PAGO QUE DESEA REALIZAR</h1>
          <div className="seleccionarPago-btn">
            <div className="seleccionarPago-btn1">
              <img src={Img23} alt="img mensualidad amistad" />
              <Link to="/pago-mensualidades">MENSUALIDADES</Link>
            </div>
            <div className={"seleccionarPago-btn1" + (!pagoCapitalHabilitado ? " deshabilitado" : "")}>
              <img src={Img24} alt="img pagos capital amistad" />
              <Link to="/pago-capital">PAGOS A CAPITAL</Link>
            </div>
            <div className="seleccionarPago-btn1">
              <img src={Img25} alt="img intereses amistad" />
              <Link to="/seleccione-servicio">PAGO DE SERVICIOS</Link>
            </div>
          </div>
        </div> */}
        <div className="seleccionarPago-prevnext">
        <Link to="/bienvenida"><div className="seleccionarPago-prevnext-btn">Regresar</div></Link>
          {/* <Link to="/"><div className="seleccionarPago-prevnext-btn">Cancelar</div></Link> */}
        </div>
      </div>
      <Amistad_Footer></Amistad_Footer>

      <Popup open={open1} className="bg" modal closeOnDocumentClick onClose={CerrarModal1}>
          <div className="modal">
            <a className="close" onClick={CerrarModal1}>
              &times;
            </a>
            <div className="aviso-capital">
              <div className="logos">
                <img src={personaje} alt="amistad personaje pago capital" width="auto" height="100"/>
              </div>
              <div className="content-text">
                <p>¡IMPORTANTE!</p>
                <h1>si usted realiza un <span>PAGO A CAPITAL 
                  </span> se pagarán las <span>últimas mensualidades </span> de su calendario. <br /> <br /> <span>¿Desea continuar?</span></h1>
                <div className="btn-capital">
                  <button onClick={CerrarModal1}><p>Cancelar</p></button>
                  <Link to="/pago-capital"><button><p>Aceptar</p></button></Link>
                </div>
              </div>
            </div>
            </div>
        </Popup>

    </div>
  );
}

export default Seleccionar_Pago;
