import React, { useState, useEffect } from 'react';
import Amistad_DatosContrato from './Amistad_DatosContrato';
import Amistad_Footer from './Amistad_Footer';
import { Link } from 'react-router-dom';
import { formatFechaISO, formatMoney, ObtenerComprobante, ObtenerEstadoCuenta } from '../back_helper';
import Comprobante from '../reportes/Comprobante';
import * as date_fns from "date-fns";
window.date_fns = date_fns;

function Amistad_Comprobantes() {
  var [registros, setRegistros] = useState([]);
  var [reporteData, setReporteData] = useState(null);

  const cargarComprobantes = async () => {
    var edocuenta = await ObtenerEstadoCuenta(localStorage.contrato);
    // var pagos = 
    var pagos_mensualidades = edocuenta.pagos;
    var pagos_servicios = edocuenta.pagos_servicios

    registros = [];
    Array.prototype.push.apply(registros, pagos_mensualidades);
    Array.prototype.push.apply(registros, pagos_servicios);
    registros.forEach(e => {e.date = date_fns.parseISO(e.Fecha).valueOf()});
    registros = registros.sort((a,b) => b.date-a.date);
    window.registros = registros;
    window.edocuenta = edocuenta;
    
    seleccionar(registros[0]);
  };

  useEffect(() => {
    cargarComprobantes();
  }, []);
  
  const seleccionar = (registro) => {
    // var seleccionado = false;
    for (let i = 0; i < registros.length; i++) {
      const e = registros[i];
      e.seleccionado = false;
      if (e == registro) {
        e.seleccionado = true;
      }
    }
    console.log("")
    setRegistros([...registros]);
  };
  
  const obtenerSeleccionado = () => {    
    var servicio_seleccionado;
    for (let i = 0; i < registros.length; i++) {
      const e = registros[i];
      if (e.seleccionado == true) {
        servicio_seleccionado = e;
        break;
      }
    }
    return servicio_seleccionado;
  }

  var imprimiendo = false;

  const ImprimirReporte = async () => {
    if(imprimiendo) return;
    imprimiendo = true;
    var seleccionado = obtenerSeleccionado();
    var data = await ObtenerComprobante(seleccionado.Tipo, seleccionado.Ticket);
    setReporteData(data);
    imprimiendo = false;
  }
  
  return (
    <div className="Amistad-comprobantes amistadFondo invertido">
        <Amistad_DatosContrato></Amistad_DatosContrato>
        <div className="pagoMens-seleccione">
        <h3>SELECCIONE EL PAGO REALIZADO</h3>
        
        <div className="PagoMens-secc1">
          {/* <div className="pagoMens-imgtext">
            <img src={ImgSelecc} alt="Amistad seleccione pago" />
            <h3>SELECCIONE LAS MENSUALIDADES A PAGAR</h3>
          </div> */}
        </div>
        <div className="PagoMens-secc2">
          <table>
            <thead>
              <tr>
                <th>FECHA</th>
                <th>CONCEPTO</th>
                <th>DESC</th>
                <th>MONTO</th>
              </tr>
            </thead>
            <tbody>
              
            {registros.map((e, i) => (
              <tr
                key={i}
                className={e.seleccionado == true ? "seleccionado" : ""}
                onClick={(ev) => seleccionar(e)}
              >
                <td>{formatFechaISO(e.Fecha)}</td>
                <td>{e.Concepto}</td>
                <td>{e.Descripcion}</td>
                <td>{formatMoney(e.Monto)}</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="seleccionarPago-prevnext">
      <Link to="/seleccionar-pago"><div className="seleccionarPago-prevnext-btn diff">Regresar</div></Link>
        <Link onClick={ImprimirReporte}><div className="verde seleccionarPago-prevnext-btn diff" >Descargar</div></Link>
      </div>
      <Amistad_Footer></Amistad_Footer>
      
      {
        reporteData == null ? "" :
        <div className="impresion-fondo">
            <Comprobante data={reporteData} onComplete={()=>setReporteData(null)}>

            </Comprobante>
          
        </div>
      }
    </div>
  )
}

export default Amistad_Comprobantes
