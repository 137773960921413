import React, { useEffect, useRef, useState } from 'react';
import Popup from "reactjs-popup"
import { guardarCita, MostarAdvertencia, obtenerHorarioAsesor, Procesar, validar } from '../back_helper';

import "flatpickr/dist/themes/dark.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { compareAsc, format, parse, parseISO } from 'date-fns'
import "./PopupCitaAgente.scss";


const PopupCitaAgente = ({datos, abiertoEstado}) => {
   console.log("datos", datos);
   const [abierto, setAbierto] = abiertoEstado;
   var [cita, setCita] = useState({
     fecha: format(Date.now(), "dd/MM/yyyy"), 
     horario: "",
     cliente: "", 
     correo : "", 
     telefono: "", 
     motivo: "Información", 
     id_asesor_sia : datos?.idAsesor,
     sucursal : datos?.sucursal
   })
   const [horarios, setHorarios] = useState([
     "05:00 PM"
   ])

   useEffect(e=> {
      setCita({
         ...cita, 
         sucursal : datos?.sucursal, 
         id_asesor_sia : datos?.idAsesor
      });
      console.log("datos", datos, "IdAsesor", datos?.idAsesor, "sucursal", datos?.sucursal);
   }, [datos]);
 

   var date_options = {
      dateFormat: 'd/m/Y', 
      locale: Spanish   
    }

   const agendarCita = async () => {
      validar(cita.fecha, "Debe ingresar una fecha");
      validar(cita.cliente, "Debe ingresar su nombre");
      validar(cita.correo, "Debe ingresar un correo electrónico de contacto");
      validar(cita.telefono, "Debe ingresar su teléfono");
      validar(cita.motivo, "Debe ingresar un motivo");
      validar(cita.horario, "Debe ingresar un horario");
  
      var res = await Procesar("Agendando Cita...", async () => await guardarCita(cita), "Cita guardada!");
      if(res.error) return;
      setAbierto(false);
    }

    const cargarHorarios = async (_fecha) => {
      if(!abierto) return;
      if(!_fecha) _fecha = cita.fecha;
      var _horarios = await obtenerHorarioAsesor(datos?.idAsesor, datos?.sucursal, _fecha);
      
      setHorarios(_horarios);
      if(!_horarios?.length)
      {
        MostarAdvertencia("No se encontraron horarios disponibles para la fecha actual, seleccione otra fecha");
        return;
      }

      setCita({ ...cita, horario: _horarios[0]?.hora});
    } 

    const cambiarFechaCita = (fecha) => {
      console.log("fecha: ", fecha);
      setCita({...cita, fecha: fecha});
      cargarHorarios(fecha);
    }

   return <Popup
   className="agendarC "
   // className={(!ComponenteIzquierdo ? "sin-izquierdo " : "") + custome?.className}
   open={abierto}
   modal
   closeOnDocumentClick={false}
   onClose={(e) => setAbierto(false)}
 >
   <div className="modal-agendar">
     <a className="close" onClick={(e) => setAbierto(false)}>
       &times;
     </a>
     <div className="popup-agendarCita">
       {/* <div className="agendarCita-element">
         <h6>Nombre del asesor</h6>
         <input type="text" name="" id="" placeholder="Ingresa el nombre del asesor" />
       </div> */}
       <div className="agendarCita-element">
         <h6>Fecha</h6>
         <Flatpickr
           options={date_options}
           placeholder="Selecciona una fecha"
           value={cita.fecha}
           // onchange={ev=>{e.horaInicio = ev}}
           onChange={(ev) => cambiarFechaCita(format(ev[0], "dd/MM/yyyy"))}
         ></Flatpickr>
       </div>
       <div className="agendarCita-element">
         <h6>Horario</h6>
         <select
           name=""
           id=""
           value={cita.horario}
           onChange={(e) => setCita({ ...cita, horario: e.target.value })}
         >
           <option value="">Seleccione un horario</option>
           {horarios.map((e) => (
             <option value={e.hora}>{e.hora}</option>
           ))}
         </select>
       </div>
       <div className="agendarCita-element">
         <h6>Motivo de la llamada</h6>
         <select
           name=""
           id=""
           value={cita.motivo}
           onChange={(e) => setCita({ ...cita, motivo: e.target.value })}
         >
           <option value="">Selecciona una opción</option>
           <option value="Información">Información</option>
           {/* <option value="">Motivo 2</option>
           <option value="">Motivo 3</option> */}
         </select>
       </div>
       <div className="agendarCita-element">
         <h6>Nombre</h6>
         <input
           type="text"
           name=""
           id=""
           placeholder="Ingresa tu Nombre"
           value={cita.cliente}
           onChange={(e) => setCita({ ...cita, cliente: e.target.value })}
         />
       </div>
       <div className="agendarCita-element">
         <h6>Correo</h6>
         <input
           type="email"
           name=""
           id=""
           placeholder="Ingresa tu correo"
           value={cita.correo}
           onChange={(e) => setCita({ ...cita, correo: e.target.value })}
         />
       </div>
       <div className="agendarCita-element">
         <h6>Número de contacto</h6>
         <input
           type="tel"
           name=""
           id=""
           placeholder="Ingresa tu celular"
           value={cita.telefono}
           onChange={(e) => setCita({ ...cita, telefono: e.target.value })}
         />
       </div>
       <div className="agendarCita-btns">
         <div className="btnAgen" onClick={(e) => setAbierto(false)}>
           Regresar
         </div>
         <div className="btnAgen" onClick={(e) => agendarCita()}>
           Agendar
         </div>
       </div>
     </div>
   </div>
 </Popup>
}


export default PopupCitaAgente; 