import React, { useEffect, useRef } from "react";
import { formatFechaISO } from "../back_helper";
import { esMonclova, Logo2 } from "../components/Amistad_Logos";
import "./Reportes.css";
import html2pdf from "html2pdf.js";

function Comprobante({data, onComplete}) {
  var elementRef = useRef(null);
  
  const Imprimir = async () => {
    var opt = {
       jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
       margin: 1,
       pagebreak: { mode: 'css'}
    };
    var nombre = "comprobante-" + data.clave_contrato + "-" + data.recibo;
    console.log("Guardando: ", nombre);

    // await html2pdf().set(opt).from(elementRef.current).save(nombre);
    await html2pdf().set(opt).from(elementRef.current).toPdf().get('pdf').then((pdf) => {
       var totalPages = pdf.internal.getNumberOfPages();
   
       for (let i = 1; i <= totalPages; i++) {
         // set footer to every page
         pdf.setPage(i);
         // set footer font
         pdf.setFontSize(10);
         pdf.setTextColor(150);
         // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
         pdf.text(pdf.internal.pageSize.getWidth() - 1.5, pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + (i) + " de " + totalPages);
        //  pdf.text(0.5, pdf.internal.pageSize.getHeight() - 0.1, data.fecha_pago);
   
          // you can add the line separator as an image, consult the docs below to properly set the place of the image
       //   pdf.addImage(img, 'png', 0, 0, 52, 23)
       }
      
     }).save(nombre);    
    if(onComplete) onComplete();
  }

  window.imprimir = Imprimir;
  

  useEffect(()=> {
      Imprimir();
  });
  console.log("Imprimiendo Comprobante", data);

  var ciudad = esMonclova ? "MONCLOVA" : "CANCÚN";
  
  return (
    <div className="reporte-comprobante"  ref={elementRef}>
      <div className="imagen-header">
         <img src={Logo2}/>         
         <img src="https://amistad.z22.web.core.windows.net/Logos/27a.png" alt="" />
      </div>
      <div className="comprobante-header">{ciudad}</div>
      <div className="comprobante-header">RECIBO DE PAGO</div>
      <div>
        <div>FECHA</div>
        <div>{data.fecha_pago}</div>
      </div>
      <div>
        <div>NO RECIBO:</div>
        <div>{data.recibo}</div>
      </div>
      <div>
        <div>CLAVE</div>
        <div>{data.clave_contrato}</div>
      </div>
      <div>
        <div>NOMBRE</div>
        <div>{data.nombre_completo}</div>
      </div>
      <div>
        <div>FRACC:</div>
        <div>{data.fraccionamiento}</div>
      </div>
      <div>
        <div>MZA:</div>
        <div>{data.mza}</div>
        <div>LOTE:</div>
        <div>{data.lote}</div>
      </div>
      <div>
        <div>CONCEPTO:</div>
        <div>{data.concepto}</div>
      </div>
      {/* {!data.servicio ? "" : 
      <div>
        <div>SERVICIO:</div>
        <div>{data.servicio}</div>
      </div>
      } */}
      <div>
        <div>PAGO:</div>
        <div>{data.pago_detalle}</div>
      </div>
      <div>
        <div>MENSUALIDAD:</div>
        <div>{data.mensualidad_monto}</div>
      </div>
      <div>
        <div>MORATORIO:</div>
        <div>{data.moratorio} </div>
      </div>
      <div>
        <div>PAGADO POR:</div>
        <div>{data.nombre_completo}</div>
      </div>
    </div>
  );
}

export default Comprobante;
