import React, { useEffect } from "react";
import "./Pago_Mensualidades.css";
import Amistad_Logos from "./Amistad_Logos";
import ImgSelecc from "../res/png_iconos/Seleccionar.png";
import { useState, useRef } from "react";
import * as date_fns from 'date-fns'
import { abrirEnlaceSelf, CrearEnlaceApartado, CrearEnlacePago, CrearEnlacePagoMensualidades, formatMoney, ObtenerCalendarioPagos, ObtenerFraccionamientos, ObtenerLotes, ObtenerManzanas } from "../back_helper";
import Swal from "sweetalert2";
import { useLocation } from "react-router";
import Amistad_Footer from "./Amistad_Footer";
import { Link } from "react-router-dom";
import Amistad_DatosContrato from './Amistad_DatosContrato';
import './Pago_asesores.css';
import {Logo2} from "./Amistad_Logos.jsx";

const LogoAmistad = Logo2;



function Pago_asesores() {

  var [fraccionamientos, setFraccionamientos] = useState([]);
  var [manzanas, setManzanas] = useState([]);
  var [lotes, setLotes] = useState([]);

  var fraccionamientoRef = useRef();
  var manzanaRef = useRef();
  var loteRef = useRef();
  var montoRef = useRef();
  var nombreRef = useRef();
  var correoRef = useRef();
  var telefonoRef = useRef();
  

  const location = useLocation();

  const cargarFraccionamientos = async () =>{
    var res = await ObtenerFraccionamientos();
    if(res.error){
      console.log(res);
      return;
    }
    setFraccionamientos(res);
  } 
  useEffect(() => {
    cargarFraccionamientos();
  }, []);

  const cargarManzanas = async () =>{
    console.log(fraccionamientoRef.current.value);
    if(!fraccionamientoRef.current.value){
      setManzanas([]);
      setLotes([]);
      return;
    }
    var res = await ObtenerManzanas(fraccionamientoRef.current.value);
    if(res.error){
      console.log(res);
      return;
    }
    setManzanas(res);
  }

  const cargarLotes = async () =>{
    console.log(manzanaRef.current.value);
    if(!manzanaRef.current.value){
      setLotes([]);
      return;
    }
    var res = await ObtenerLotes(fraccionamientoRef.current.value, manzanaRef.current.value);
    if(res.error){
      console.log(res);
      return;
    }
    setLotes(res);
  }

  


  var procesando = false;
  const realizarPago = async () => {      
    if(procesando) return;
    procesando = true;
    console.log(fraccionamientoRef.current.value, manzanaRef.current.value, loteRef.current.value,
       montoRef.current.value, nombreRef.current.value, correoRef.current.value, telefonoRef.current.value);
    
    if(!loteRef.current.value){
      Swal.fire({
          icon: "error",
          title: "Debe seleccionar un lote.",
      });
      procesando = false;
      return;
    }
    if(parseFloat(montoRef.current.value) < 500){
      Swal.fire({
          icon: "error",
          title: "Debe ingresar un monto mayor a 500.",
      });
      procesando = false;
      return;
    }
    if(parseFloat(montoRef.current.value) % 50 != 0){
      Swal.fire({
          icon: "error",
          title: "El monto debe ser multiplo de 50.",
      });
      procesando = false;
      return;
    }
    if(!nombreRef.current.value){
      Swal.fire({
          icon: "error",
          title: "Debe ingresar el nombre del cliente.",
      });
      procesando = false;
      return;
    }
    if(!correoRef.current.value){
      Swal.fire({
          icon: "error",
          title: "Debe ingresar el correo del cliente.",
      });
      procesando = false;
      return;
    }
    if(!telefonoRef.current.value){
      Swal.fire({
          icon: "error",
          title: "Debe ingresar el teléfono del cliente.",
      });
      procesando = false;
      return;
    }
    var data = await CrearEnlaceApartado(loteRef.current.value, nombreRef.current.value, correoRef.current.value,
      telefonoRef.current.value, montoRef.current.value, true, window.localStorage.asesor);
    if(data.error)
    {
      Swal.fire({
         icon: "error",
         title: data.error,
      });
      procesando = false;
      return;
    }
    abrirEnlaceSelf(data.enlace);
  }

  // console.log("registros", registros);
  return (
    <div className="pagoMens-main amistadFondo invertido">
       <div className="amistad_datosContrato-body">
         <div className="amistad_datosC-container">
            <div className="amistad_datosC-Img">
               <img src={LogoAmistad} alt="imagen amistad logo" />
            </div>
            <div className="amistad_datosC-inputs">
               <div className="amistad_datosC-content">
                  <div className="amistad_separador"></div>
                  <input type="text" value={window.localStorage.asesor}
                     // onChange={e=>setContratoNombre(e.target.value)}
                  />
               </div>
            </div>
         </div>
      </div>
    
      <div className="pagoMens-container">
         <div className="pagoAsesores-content">
            <div>
               <label htmlFor="">Fraccionamiento</label>
               <select name="" id="" ref={fraccionamientoRef} onChange={cargarManzanas}>
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {
                    fraccionamientos.map((fraccionamiento) => 
                    <option value={fraccionamiento.Fraccionamiento}>{fraccionamiento.Fraccionamiento}</option>
                    )
                  }
               </select>
            </div>
            <div>
               <label htmlFor="">Manzana</label>
               <select name="" id="" ref={manzanaRef} onChange={cargarLotes}>
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {
                    manzanas.map((manzana) => 
                    <option value={manzana.manzana}>{manzana.manzana}</option>
                    )
                  }
               </select>
            </div>
            <div>
               <label htmlFor="">Lote</label>
               <select name="" id="" ref={loteRef}>
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {
                    lotes.map((lote) => 
                    <option value={lote.id}>{lote.lote}</option>
                    )
                  }
               </select>
            </div>
            <div>
               <label htmlFor="">Monto a pagar</label>
               <input type="text" ref={montoRef}/>
            </div>
            <h1>DATOS DEL CLIENTE</h1>
            <div>
               <label htmlFor="">Nombre</label>
               <input type="text" ref={nombreRef} />
            </div>
            <div>
               <label htmlFor="">Correo</label>
               <input type="text" ref={correoRef} />
            </div>
            <div>
               <label htmlFor="">Teléfono</label>
               <input type="text" ref={telefonoRef} />
            </div>
         </div>
        {/* <div className="PagoMensTotal-container">
        <div className="PagoMens-total">
          <div className="PagoMens-totalPartes">
          <table>
            <tr>
              <td>SUBTOTAL</td>
              <td>{formatMoney(state.subtotal, 2, ".", ",")}</td>
              <td>RECARGO</td>
              <td>{formatMoney(state.recargos, 2, ".", ",")}</td>
            </tr>
            <tr>
              <td colSpan="3" style={{backgroundColor:"#f0840b", color:"#fff", letterSpacing:"0.2em"}}>TOTAL A PAGAR</td>
              <td>{formatMoney(state.total, 2, ".", ",")}</td>
            </tr>
          </table>
          </div>
        </div>
        </div> */}
        <div className="seleccionarPago-prevnext ">
        <Link to="/bienvenida-asesores"><div className="seleccionarPago-prevnext-btn diff">Regresar</div></Link>
          <Link to="/bienvenida-asesores"><div className="seleccionarPago-prevnext-btn diff">Cancelar</div></Link>
          <Link><div className="seleccionarPago-prevnext-btn diff" onClick={realizarPago}><i class="far fa-credit-card"></i>Apartar</div></Link>
        </div>
        {/* <div className="PagoMens-btnsA">
          <div className="seleccionarPago-btnCan2">
            <Link to="/bienvenida">
              <button>Cancelar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnCan2 dos">
            <Link to="/seleccionar-pago">
              <button>Regresar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnAcep" onClick={realizarPago}>
            <button>Pagar</button>
          </div>
        </div> */}
      </div>
      <Amistad_Footer></Amistad_Footer>
    </div>
  );
}

export default Pago_asesores
