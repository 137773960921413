import React, { useEffect, useRef } from "react";
import { formatFechaISO, formatMoney } from "../back_helper";
import html2pdf from "html2pdf.js";
// import { Logo2} from "../components/Amistad_Logos";
import Logo  from "../res/edoCtaRes/logo.png";
import  Logo2  from "../res/edoCtaRes/desde1995.png";
import  Redes  from "../res/edoCtaRes/dredes.png";
import html2canvas from "html2canvas";
import "./Reportes.css";      

function EstadoCuenta({data, onComplete}) {
   var elementRef = useRef();

   var tablas_calendario = [[],[],[]]; 
   var adeudos = [];

   //organizamos tablas en 3
   let monto = 0;
   let recargo = 0;
   let total = 0;
   for (let i = 0; i < data.calendario.length; i++) {
      const e = data.calendario[i];
      tablas_calendario[i%3].push(e);
      //Si el pago está vencido, lo agregamos a la sumatoria de adeudos
      if(e["Pagada"] == 'VENCIDA')
      {
         monto += e["Cantidad"];
         recargo += e["Recargo"] ?? 0;
         total = monto + recargo;
      }
   }
   
   if(monto > 0) {
      adeudos.push({
         Concepto: "LOTE",
         Monto: monto,
         Recargo: recargo,
         Total: total
      });
   }

   var tablas_pagos = [[], []];
   //organizamos tablas en 3
   for (let i = 0; i < data.pagos.length; i++) {
      const e = data.pagos[i];
      tablas_pagos[i%2].push(e);
   }


   var tablas_servicios = [];

   for (let i = 0; i < data.servicios.length; i++) {
      const servicio = data.servicios[i];
      console.log(servicio.Servicio, servicio.Pagos);
      servicio.tablas = [[], []];
      let monto = 0;
      let recargo = 0;
      let total = 0;

      for (let y = 0; y < servicio.Pagos.length; y++) {
         const e = servicio.Pagos[y];

         //Si el pago está vencido, lo agregamos a la sumatoria de adeudos
         if(e["Pagada"] == 'VENCIDA')
         {
            monto += e["Monto"];
            recargo += e["Recargo"] ?? 0;
            total = monto + recargo;
         }

         servicio.tablas[y%2].push(e);
      }

      adeudos.push({
         Concepto: servicio.Servicio,
         Monto: monto,
         Recargo: recargo,
         Total: total
      })

      tablas_servicios.push(servicio);
   }

   window.adeudos = adeudos;
   
   adeudos.push({
      Concepto: "PAGO TOTAL REQUERIDO",
      Monto: adeudos.reduce((a,b) => (a??0) + b["Monto"], 0),
      Recargo: adeudos.reduce((a,b) => (a??0) + b["Recargo"], 0),
      Total: adeudos.reduce((a,b) => (a??0) + b["Total"], 0)

   });

   const Imprimir = async () => {
      var opt = {
         jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
         margin: 0.5,
         pagebreak: { mode: 'css'},
         html2canvas:  { dpi: 300, letterRendering: true },
      };
      console.log("generando pdf", elementRef.current);
      // html2canvas(elementRef.current).then(function(canvas) {
      //     window.document.body.appendChild(canvas); 
      // });
      // return;

      // await html2pdf().from(elementRef.current).set(opt).outputImg().save("edocta-" + data.contrato.Contrato);
      await html2pdf().set(opt).from(elementRef.current).toPdf().get('pdf').then((pdf) => {
         var totalPages = pdf.internal.getNumberOfPages();
     
         for (let i = 1; i <= totalPages; i++) {
           // set footer to every page
           pdf.setPage(i);
           // set footer font
           pdf.setFontSize(10);
           pdf.setTextColor(150);
           // this example gets internal pageSize just as an example to locate your text near the borders in case you want to do something like "Page 3 out of 4"
           pdf.text(pdf.internal.pageSize.getWidth() - 1.5, pdf.internal.pageSize.getHeight() - 0.1, 'Página ' + (i) + " de " + totalPages);
           pdf.text(0.5, pdf.internal.pageSize.getHeight() - 0.1, data.Fecha);
     
            // you can add the line separator as an image, consult the docs below to properly set the place of the image
         //   pdf.addImage(img, 'png', 0, 0, 52, 23)
         }
        
       }).save("edocta-" + data.contrato.Contrato);    
      if(onComplete) onComplete();
   }

   window.imprimir = Imprimir;
   
   var total_pagos_efectuados = data.pagos.reduce((a,b) => a + b["Monto"], 0);
   var total_mora_pagada = data.pagos.reduce((a,b) => a + b["Mora"], 0);

   useEffect(()=> {
      Imprimir();
   }, []);

  return (
    <div className="reporte-edocta" ref={elementRef}>
      <div className="reporte-edocta-header">
        {/* <img src={Logo2}/>          */}
        <div clasName="reporte-header">Estado de Cuenta</div>
        {/* <img src="https://amistad.z22.web.core.windows.net/Logos/27a.png" alt="" /> */}
      </div>
      <div className="borderExt"></div>
      <div className="containerEstilo1" >
        <div className="box">
          <img src={Logo} alt="logo amistad bienes raices" />
          <img src={Logo2} alt="logo amistad bienes raices" />
        </div>
        <div className="box">
          <div className="reporte-edocta-estilo1 reporte-edocta-margin data">
            <h1>CLIENTE: </h1>
            <p>{data.contrato.Nombre}</p>
          </div>
          <div className="reporte-edocta-estilo1 lote">
            <div className="data">
              <h1>FRACC: </h1>
              <p>{data.contrato.Fraccionamiento}</p>
            </div>
            <div className="data">
              <h1>MZA: </h1>
              <p>{data.contrato.Mza}</p>
            </div>
            <div className="data">
              <h1>LOTE: </h1>
              <p>{data.contrato.Lote}</p>
            </div>
          </div>
          <div className="reporte-edocta-estilo1">
            <div className="data">
              <h1>CLAVE DE CONTRATO: </h1>
              <p>{data.contrato.Contrato}</p>
            </div>
            <div className="data">
              <h1>FECHA DE CONTRATO: </h1>
              <p>{formatFechaISO(data.contrato.FechaContrato)}</p>
            </div>
          </div>
          <div className="reporte-edocta-estilo1">
            <div className="data">
              <h1>VENDEDOR: </h1>
              <p>{data.contrato.Vendedor}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="reporte-subtitulo">
        <h1>RESUMEN DE PAGOS DE LOTE</h1>
      </div>
      <div className="reporte-edocta-estilo2">
        <div className="subtituloText">PRECIO DE LOTE </div>
        <div className="cifrasText">{formatMoney(data.resumen.PrecioLote)}</div>
        <div className="subtituloText">PLAN DE PAGOS</div>
        <div className="cifrasText">{data.resumen.PlanPagos}</div>
      </div>
      <div className="reporte-edocta-estilo2">
        <div className="subtituloText">MONTO INGRESADO </div>
        <div className="cifrasText">{formatMoney(data.resumen.Ingresado)}</div>
        <div className="subtituloText">PAGADO</div>
        <div className="cifrasText">{data.resumen.Pagado}</div>
      </div>
      <div className="reporte-edocta-estilo2">
        <div className="subtituloText">AHORRO</div>
        <div className="cifrasText">{formatMoney(data.resumen.Ahorro)}</div>
        <div className="subtituloText">PAGOS PENDIENTES</div>
        <div className="cifrasText">{data.resumen.PagosPendientes}</div>
      </div>
      <div className="reporte-edocta-estilo2">
        {/* <div></div>
        <div></div> */}
        <div className="bgText">
          <div className="subtituloText">MONTO PENDIENTE</div>
          <div className="cifrasText">
            {formatMoney(data.resumen.MontoPendiente)}
          </div>
        </div>
      </div>
      <div className="reporte-subtitulo noMargin">
        <h1>CALENDARIO</h1>
      </div>
      <div className="reporte-edocta-calendarios">
        {tablas_calendario.map((calendario) => (
          <table>
            <tr className="bg-trs">
              <th>N°</th>
              <th>Fecha</th>
              <th>Cantidad</th>
              <th>Estatus</th>
            </tr>
            {calendario.map((reg) => (
              <tr>
                <td>{reg.Num}</td>
                <td>{formatFechaISO(reg.Fecha)}</td>
                <td>{formatMoney(reg.Cantidad)}</td>
                <td className="strong">{reg.Pagada}</td>
              </tr>
            ))}
          </table>
        ))}
      </div>
      <div className="reporte-subtitulo noMargin">
        <h1>DETALLE DE PAGOS</h1>
      </div>
      <div className="reporte-edocta-pagos">
        {tablas_pagos.map((pagos) => (
          <table>
            <tr className="bg-trs">
              <th>Ticket</th>
              <th>Fecha</th>
              <th>Monto</th>
              <th>Mora</th>
              <th>F/Pago</th>
              <th>Descripción</th>
            </tr>
            {pagos.map((pago) => (
              <tr>
                <td>{pago.Ticket}</td>
                <td>{formatFechaISO(pago.Fecha)}</td>
                <td>{formatMoney(pago.Monto)}</td>
                <td>{pago.Mora}</td>
                <td>{pago.FPago}</td>
                <td>{pago.Descripcion}</td>
              </tr>
            ))}
          </table>
        ))}
      </div>
      <div className="reporte-edocta-estilo3">
        <div>TOTAL HISTORICO DE PAGOS YA EFECTUADOS</div>
        <div>{formatMoney(total_pagos_efectuados)}</div>
        {/* <div>{formatMoney(total_mora_pagada)}</div> */}
      </div>
      <div className="reporte-subtitulo noMargin">
        <h1>CALENDARIO SERVICIOS</h1>
      </div>
      {tablas_servicios.map((servicio) => (
        <div className="reporte-edocta-servicio">
          <div className="reporte-edocta-header-servicio">
            {servicio.Servicio}
          </div>
          <div className="reporte-edocta-servicio-tablas">
            {servicio.tablas.map((pagos) => (
              <table>
                <tr className="bg-trs">
                  <th>N°</th>
                  <th>Fecha</th>
                  <th>Monto</th>
                  <th>Estatus</th>
                </tr>
                {pagos.map((pago) => (
                  <tr>
                    <td>{pago.Num}</td>
                    <td>{formatFechaISO(pago.Fecha)}</td>
                    <td>{formatMoney(pago.Monto)}</td>
                    <td className="strong">{pago.Pagada}</td>
                  </tr>
                ))}
              </table>
            ))}
          </div>
        </div>
      ))}
      <table className="reporte-edocta-adeudos reporte-edocta-margin">
        <tr className="bg-trtitle">
          <th>ADEUDO DE:</th>
          <th>MONTO</th>
          <th>MORATORIO</th>
          <th>TOTAL</th>
        </tr>
        {adeudos.map((adeudo) => (
          <tr>
            <td>{adeudo.Concepto}</td>
            <td>{formatMoney(adeudo.Monto)}</td>
            <td>{formatMoney(adeudo.Recargo)}</td>
            <td>{formatMoney(adeudo.Total)}</td>
          </tr>
        ))}
      </table>
      <div className="footer-EdoCta">
        <img src={Redes} alt="amistad redes sociales" />
      </div>
    </div>
  );
}

export default EstadoCuenta;
