import * as date_fns from "date-fns";
import Swal from "sweetalert2";

const back_url = "";

export const CrearEnlacePagoMensualidades = async (contrato, mensualidades, correo, telefono) => {

   contrato = encodeURIComponent(contrato);
   var res = await fetch(back_url + "/back/crear-enlace-mensualidades?contrato=" + contrato + "&correo=" + correo + "&telefono=" + telefono + "&mensualidades=" + mensualidades);
   var data = await res.json();
   return data;
}

export const CrearEnlacePagoCapital = async (contrato, mensualidad_clave, correo, telefono) => 
{
   console.log("CrearEnlacePagoCapital", contrato, mensualidad_clave, correo);
   var res = await fetch(back_url + "/back/crear-enlace-capital?contrato=" + contrato + "&correo=" + correo + "&telefono=" + telefono + "&mensualidad_clave=" + mensualidad_clave);
   var data = await res.json();
   return data;
}

export const CrearEnlacePagoServicios = async (contrato, servicio, mensualidades, correo, telefono) => {

   var res = await fetch(back_url + "/back/crear-enlace-servicio?contrato=" + contrato +"&servicio=" + servicio + "&correo=" + correo + "&telefono=" + telefono + "&mensualidades=" + mensualidades);
   var data = await res.json();
   return data;
}

export const CrearEnlaceApartado = async (idLote, nombre, correo, telefono, monto, esAsesor=true, asesor = "") => {

   var res = await fetch(back_url + "/back/crear-enlace-apartado?idLote=" + idLote + 
      "&nombre=" + nombre + "&correo=" + correo + "&telefono=" + telefono + "&monto=" + monto + "&esAsesor=" + esAsesor + "&asesor=" + asesor
   );
   var data = await res.json();
   return data;
}

export const CrearCita = async (contrato, agente, fecha, horario, motivo, correo, telefono, comentario, tipo, agente_telefono) => {
   console.log(contrato);
   var res = await fetch(back_url + "/back/crear-cita?contrato=" + contrato + "&agente=" + agente + "&fecha=" + fecha + "&horario=" + horario + "&motivo=" + motivo + "&correo=" + correo + "&telefono=" + telefono + "&comentario=" + comentario + "&tipo=" + tipo + "&agente_telefono=" + agente_telefono);
   var data = await res.json();
   return data;
}

export const CrearEnlacePago = async (contrato, cantidad, correo, mensualidades) => {

   var res = await fetch(back_url + "/back/crear-enlace-anterior?contrato=" + contrato + "&monto=" + cantidad + "&correo=" + correo + "&mensualidades=" + mensualidades);
   var data = await res.json();
   return data; 
}


export const ObtenerContrato = async (contrato) => {

   contrato = encodeURIComponent(contrato);
   var res = await fetch(back_url + "/back/contrato/" + contrato);
   var data = await res.json();
   return data;
}

export const ObtenerCalendarioPagos = async (contrato) => {

   var res = await fetch(back_url + "/back/contrato/" + contrato + "/calendario");
   var data = await res.json();
   return data;
}

export const ObtenerServicios = async (contrato) => {

   var res = await fetch(back_url + "/back/contrato/" + contrato + "/servicios");
   var data = await res.json();
   return data;
}

export const ObtenerCalendarioServicio = async (contrato, servicio) => {

   var res = await fetch(back_url + "/back/contrato/" + contrato + "/servicios/" + servicio + "/calendario");
   var data = await res.json();
   return data;
}


export const ObtenerEstadoCuenta = async (contrato) => {
   var res = await fetch(back_url + "/back/contrato/" + contrato + "/estadocuenta");
   var data = await res.json();
   return data;
}


export const ObtenerComprobante = async (tipo, folio) => {
   var res = await fetch(back_url + "/back/comprobante/" +  tipo + "/" + folio);
   var data = await res.json();
   return data;
}

export const BuscarNoContrato = async (primer_nombre, segundo_nombre, apellido_paterno, apellido_materno, fraccionamiento, manzana, lote) => {
   var res = await fetch(back_url + "/back/buscar_contrato?" + 
      `primer_nombre=${primer_nombre}&` +
      `segundo_nombre=${segundo_nombre}&` +
      `apellido_paterno=${apellido_paterno}&` +
      `apellido_materno=${apellido_materno}&` +
      `fraccionamiento=${fraccionamiento}&` +
      `manzana=${manzana}&` +
      `lote=${lote}`
   );
   var data = await res.json();
   return data;
}

export const abrirEnlace = async(url) => {
   var a = window.document.createElement("a");
   a.href = url;
   a.target = "_blank";
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
 }
 
export const abrirEnlaceSelf = async(url) => {
   var a = window.document.createElement("a");
   a.href = url;
   a.target = "_self";
   document.body.appendChild(a);
   a.click();
   document.body.removeChild(a);
 }

export function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",", symbol = "$") {
   try {
     decimalCount = Math.abs(decimalCount);
     decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
 
     const negativeSign = amount < 0 ? "-" : "";
 
     let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
     let j = (i.length > 3) ? i.length % 3 : 0;
 
     var result= negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
     // if(result.endsWith(".00"))
     //   result = result.replace(".00", "");
     return symbol + result;
   } catch (e) {
     console.log(e)
   }
 };

 export function formatFechaISO(fecha)
 {
   return date_fns.format(
      date_fns.parseISO(fecha),
      "dd/MM/yyyy"
   );
 }

export const ObtenerHorariosDisponible = async (agente, fecha) => {

   var res = await fetch(back_url + "/back/horarios?agente=" + agente + "&fecha=" + fecha);
   var data = await res.json();
   return data;
}

export const ObtenerFraccionamientos = async () => {

   var res = await fetch(back_url + "/back/fraccionamientos");
   var data = await res.json();
   return data;
}

export const ObtenerFraccionamientosEncabezados = async () => {

   var res = await fetch(back_url + "/back/fraccionamientos/encabezados");
   var data = await res.json();
   return data;
}

export const ObtenerManzanas = async (fraccionamiento) => {

   var res = await fetch(back_url + "/back/manzanas/" + fraccionamiento);
   var data = await res.json();
   return data;
}

export const ObtenerLotes = async (fraccionamiento, manzana) => {

   var res = await fetch(back_url + "/back/lotes/" + fraccionamiento + "/" + manzana);
   var data = await res.json();
   return data;
}

export const ObtenerFaqData = async (contrato) => {

   var res = await fetch(back_url + "/back/faq?contrato=" + contrato);
   var data = await res.json();
   return data;
}
// export const ObtenerAsesor = async (id) => {

//    var res = await fetch(back_url + "/back/asesor/" + id);
//    var data = await res.json();
//    return data;
// }

export const ObtenerAsesores = async () => {

   var res = await fetch(back_url + "/back/asesores");
   var data = await res.json();
   return data;
}

export const ValidarAsesor = async (asesor, contraseña) => {
   var res = await fetch(back_url + "/back/validar_asesor", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
         asesor: asesor, 
         contraseña: contraseña
      })
   });

   var data = await res.json();
   return data;
}


export const ObtenerAgente = async (contrato) => {

   var res = await fetch(back_url + "/back/agente/" + contrato );
   var data = await res.json();
   return data;
}



/**
 * 
 * @param {{fecha: "", horario: "", cliente: "", correo : "", telefono: "", motivo: "", codigo_link: ""}} cita 
 * @returns 
 */
 export const guardarCita = async (cita) => {
   var res = await fetch(back_url + "/asesores/cita/", {
      method: 'POST',
      headers: {
        'token': localStorage.token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(cita)
   });
 
   var data = await res.json();
   return data;
 }

 export const obtenerHorarioAsesor = async (id_asesor_sia, sucursal, fecha) => {
   var url = back_url + "/asesores/horarios_idsia/" + sucursal + "/" + id_asesor_sia + "?fecha=" + fecha;
   console.log(fecha, encodeURIComponent(fecha), url);
    var res = await fetch(url, {
       method: 'GET',
       headers: {
         'token': localStorage.token
       }
    });
 
    var data = await res.json();
    return data;
 }
 
 export const ValidarContratoRegistro = async (contrato) => 
 {
   var res = await fetch(back_url + `/user/validar-contrato/${contrato}`);
   var data = await res.json();
   return data;
 }

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////           FUNCIONES UTILIDADES                          //////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const validar = (validacion, mensaje) => {
   // console.log("validando: ", validacion);
   if(!validacion)
   {
      // console.log("Validación incorrect1a");
      MostarAdvertencia(mensaje);
      throw mensaje;
   }
   return true;
 }

 
export const MostarAdvertencia = async (text) => {
   await Swal.fire({
      icon: "warning",
      title: text
   });
}


export const MostrarError = async (mensaje) => {
   await Swal.fire({
      icon: "error",
      title: mensaje
   });
 }

export const MostrarProcesando = async (texto) => {
   Swal.fire({
      title: texto ?? "Procesando...",
      timer: 120000,
      timerProgressBar: true,
      showConfirmButton : false,      
      didOpen: () => {
         Swal.showLoading()
      }
   });
 }
 
 export const CerrarProcesando = async (texto) => {
   await Swal.close();
 }
 
 export const MostrarExitoso = async (mensaje) => {
   return await Swal.fire({
      icon: "success",
      title: mensaje
   });
 }

var procesando = false;

export const Procesar = async (texto, proceso, mensajeExitoso, mensajeError) => {
  if(procesando) return;
  procesando = true;
  MostrarProcesando(texto);
  var res;
  try {
     res = await proceso();
  } catch (error) {
     await CerrarProcesando();
     await MostrarError(mensajeError ?? "ups, parece que ocurrio un error");
     console.error(error);
     procesando = false;
     return {error:"ocurrio un error al realizar la petición"};
  }
  await CerrarProcesando();

  if(res?.error)
  {
     await MostrarError(mensajeError ?? res.error);
     console.error(res.error);
     procesando = false;
     return res;
  }

  if(mensajeExitoso)
     await MostrarExitoso(mensajeExitoso);
     
  procesando = false;
  return res;
  
}