import React from "react";
import "./forma-pago.css";
import amistadlogo from "../res/amistadlogo.png";
import logoImg from "../res/logo.svg";
import bankDep from "../res/payment.svg";
import bankTransferImg from "../res/bank-transfer.svg";
import { useState } from "react";
import Popup from "reactjs-popup";
import ReportarDeposito from "./reportar-deposito"
import { withRouter } from "react-router-dom";
import Amistad_Logos from "./Amistad_Logos";
import { abrirEnlaceSelf, CrearEnlacePago } from "../back_helper";

const FormaPago = ({history}) => {
  const _datosContrato = window.localStorage.datosContrato ? JSON.parse(window.localStorage.datosContrato) : {};
  
  const [datosContrato, setDatosContrato] = useState(_datosContrato);
  console.log(datosContrato);
  console.log(_datosContrato);
  const [formaPago, setFormaPago] = useState("tarjeta");
  
  const [open, setOpen] = useState(false);
  const CerrarModal = () => setOpen(false);
  const AbrirModal = () => setOpen(true);
  
  const [open2, setOpen2] = useState(false);
  const CerrarModal2 = () => setOpen2(false);
  const AbrirModal2 = () => setOpen2(true);
  
  const RealizarAbono = async () => {
    var enlace = await CrearEnlacePago(_datosContrato.contrato, _datosContrato.mensualidad, _datosContrato.correo);
    abrirEnlaceSelf(enlace);
  }

  if(!window.localStorage.datosContrato)
  {
    history.push("/portal");
    return <div>Debes seleccionar un contrato antes</div>;
  }

  return (
    <div className="FormaPago-main amistadFondo">
      <Amistad_Logos></Amistad_Logos>
      <div className="FormaPago-contenido">
        <div className="FormaPago-titulo">Información del cliente</div>
        <div className="FormaPago-informacionC">
          <div className="FormaPago-informacion-field">
            <div className="FormaPago-dato">Nombre:</div>
            <div className="FormaPago-dato">{datosContrato?.nombre}</div>
          </div>
          <div className="FormaPago-informacion-field">
            <div className="FormaPago-dato">Contrato:</div>
            <div className="FormaPago-dato">{datosContrato?.contrato}</div>
          </div>
          <div className="FormaPago-informacion-field">
            <div className="FormaPago-dato">Total del adeudo:</div>
            <div className="FormaPago-dato">${datosContrato?.adeudo}</div>
          </div>
          <div className="FormaPago-informacion-field">
            <div className="FormaPago-dato">Pago mensual.</div>
            <div className="FormaPago-dato">${datosContrato?.mensualidad}</div>
          </div>
          <div className="FormaPago-informacion-field">
            <div className="FormaPago-dato">Fecha límite para abonar:</div>
            <div className="FormaPago-dato">{datosContrato?.fecha_limite}</div>
          </div>
        </div>
        <div className="FormaPago-elegirForma-titulos">
          <div className="FormaPago-elegirForma-titulo">Forma de pago</div>
          <div className="FormaPago-elegirForma-subtitulo">
            Elige tu forma de pago
          </div>
        </div>
        <div className="FormaPago-tarjetas">
          <div
            className={
              "FormaPago-tarjeta " +
              (formaPago == "tarjeta" && "FormaPago-activo")
            }
            onClick={() => {
              setFormaPago("tarjeta");
            }}
          >
            <div>Tarjeta de crédito/débito</div>
            <img src={logoImg} alt="imagen tarjeta credito"></img>
          </div>
          <div
            className={
              "FormaPago-tarjeta " +
              (formaPago == "transferencia" && "FormaPago-activo")
            }
            activo={formaPago == "transferencia"}
            onClick={() => {
              setFormaPago("transferencia");
            }}
          >
            <div>Transferencia interbancaria</div>
            <img src={bankTransferImg} alt="imagen transferencia banco "></img>
          </div>
          <div
            className={
              "FormaPago-tarjeta " +
              (formaPago == "deposito" && "FormaPago-activo")
            }
            activo={formaPago == "deposito"}
            onClick={() => {
              setFormaPago("deposito");
            }}
          >
            <div>Depósito en bancos</div>
            <img src={bankDep} alt="imagen deposito banco"></img>
          </div>
        </div>
        {formaPago == "tarjeta" && (
          <div className="alinear-elementosPago">
            
            <div className="FormaPago-datosTarjeta">
              {/* <div className="FormaPago-datosTarjetas-field">
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="Número de tarjeta" />
                </div>
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="Nombre y apellido" />
                </div>
              </div>
              <div className="FormaPago-datosTarjetas-field">
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="Fecha de expiracion" />
                </div>
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="CVV" />
                </div>
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="Monto" />
                </div>
              </div> */}
              {/* <div className="FormaPago-datosTarjeta-inf"> 
                Pago Mensual
              </div>
              <div className="FormaPago-datosTarjetas-field monto">
                <div className="FormaPago-datosTarjeta-inf">
                  <input type="text" placeholder="Monto" readonly value={datosContrato?.mensualidad} />
                </div>
              </div> */}
              <div className="FormaPago-datosTarjetas-field">
                <div className="FormaPago-datosTarjeta-inf">
                  <div className="FormaPago-boton" onClick={RealizarAbono}>Realizar Pago Mensual</div>
                </div>
              </div>
            </div>
            <Popup open={open2} modal closeOnDocumentClick onClose={CerrarModal2}>
              <div className="modal">
                <a className="close" onClick={CerrarModal2}>
                  &times;
                </a>
                <iframe 
                  src="https://wppsandbox.mit.com.mx/i/SNDBX001"
                  width="320x" 
                  height="480px" 
                  frameborder="0" 
                  scrolling="no"
                  seamless="seamless"
                ></iframe>
              </div>
            </Popup>
          </div>
        )}

        {formaPago == "transferencia" && (
          <div className="alinear-elementosPago">
            <div className="pago-instruccionesTrans">
              <div className="pago-instrucciones-field">
                <div className="pago-instrucciones-texto">
                  <strong>
                    Para realizar el pago mediante transferencia bancaria (SPEI)
                    ingresa los siguientes datos en tu aplicación de banco:
                  </strong>
                  <br />
                  <br />
                  <strong>Paso 1.</strong> Ingresa a tu aplicación o portal de
                  tu banco.
                  <br />
                  <strong>Paso 2.</strong> Ingresa la CLABE interbancaria:
                  014691655055910814
                  <br />
                  <strong>Paso 3.</strong> Ingresa el monto a transferir
                  <br />
                  <strong>Paso 4.</strong> Ingresa el concepto de transferencia
                  el cual son las dos referencias que vienen al reverso de tu
                  tarjeta amistad.
                  <br />
                  <br />
                  Al finalizar la operación, toma foto del comprobante y
                  repórtalo al número de atención con tu Nombre,
                  Fraccionamiento, Manzana y Lote.
                </div>
              </div>
            </div>
          </div>
        )}
        {formaPago == "deposito" && (
          <div className="alinear-elementosPago">
            <div className="pago-instruccionesTrans">
              <div className="pago-instrucciones-field">
                <div className="pago-instrucciones-texto">
                  <strong>
                    Para realizar el pago mediante practicajas o ventanilla en
                    banco ingresa los siguientes datos:
                  </strong>
                  <br />
                  <br />
                  <strong>Paso 1.</strong> Acude a cualquier sucursal de Banco
                  Santander
                  <br />
                  <strong>Paso 2.</strong> Ingresa o proporciona el número de
                  cuenta: 65-50559108-1
                  <br />
                  <strong>Paso 3.</strong> Ingresa o proporciona el monto a
                  depositar
                  <br />
                  <strong>Paso 4.</strong> Ingresa o proporciona el concepto de
                  transferencia el cual son las dos referencias que vienen al
                  reverso de tu tarjeta amistad.
                  <br />
                  <br />
                  Al finalizar la operación, toma foto del comprobante y
                  repórtalo al número de atención con tu Nombre,
                  Fraccionamiento, Manzana y Lote.
                </div>
              </div>
              <div className="deposito-boton" onClick={AbrirModal}>
                Reportar depósito
              </div>
              <Popup open={open} modal closeOnDocumentClick onClose={CerrarModal}>
                <div className="modal">
                  <a className="close" onClick={CerrarModal}>
                    &times;
                  </a>
                  <ReportarDeposito></ReportarDeposito>
                </div>
              </Popup>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(FormaPago);
