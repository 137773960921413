import React, { useEffect } from "react";
import "./Pago_Mensualidades.css";
import Amistad_Logos from "./Amistad_Logos";
import ImgSelecc from "../res/png_iconos/Seleccionar.png";
import { useState, useRef } from "react";
import * as date_fns from 'date-fns'
import { abrirEnlaceSelf, CrearCita, CrearEnlacePago, CrearEnlacePagoMensualidades, formatMoney, ObtenerAgente, ObtenerCalendarioPagos, ObtenerHorariosDisponible } from "../back_helper";
import Swal from "sweetalert2";
import { useLocation, withRouter } from "react-router";
import Amistad_Footer from "./Amistad_Footer";
import { Link } from "react-router-dom";
import Amistad_DatosContrato from './Amistad_DatosContrato';
import './Pago_asesores.css';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import { Component } from "react";
import { compareAsc, format, parse, addDays } from 'date-fns'

const tipoCitaPresencial = [
  "ACUERDO DE PAGOS",
  "INFORMACIÓN PARA CAMBIO DE PROPIETARIO",
  "INFORMACIÓN PARA REUBICACIÓN",
  "ENTREGA DE CONVENIO FIRMADO",
  "ENTREGA DE DOCUMENTACIÓN",
  "CANCELACIÓN DE FINANCIAMIENTO"  
]

const tipoCitaLlamada = [
  "ACUERDO DE PAGO EN MENSUALIDADES",
  "ACLARACIONES DE PAGOS",
  "ACLARACIONES/ACUERDOS DE PAGO",
  "INFORMACIÓN DEL FINANCIAMIENTO",
  "INFORMACIÓN PARA CAMBIO DE PROPIETARIO",
  "INFORMACIÓN PARA REUBICACIÓN",
  "CANCELACIÓN DEL FINANCIAMENTO",
  "INFORMACIÓN DE TRÁMITE DE LOTE FINIQUITADO EN FRACCIONAMIENTO MÉXICO",
  "INFORMACIÓN DE TRÁMITE DE LOTE FINIQUITADO EN FRACCIONAMIENTO ALAMOS",
  "INFORMACIÓN LOTE FINIQUITADO",
  "SOLICITUD DE PUNTOS TOPOGRÁFICOS",
]

function Cita_datos({history}) {
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);

  var citaTopografico = query.get("topo");


  var [agente, setAgente] = useState("");
  var [horarios, setHorarios] = useState([])

  async function consultarHorario(){
    var res = await ObtenerHorariosDisponible(agente.agente, fecha);
   if(res.error){
     console.log(res);
     return
   }
   setHorarios(res);
  //  window.horario = horarioRef.current;
   if(res.length == 0){
     Swal.fire({
          icon: "error",
          title: "Estimado cliente, el horario seleccionado no se encuentra disponible, favor de cambiar de día.",
      });
      return false;
   }
   
  //  horario = res[0];
   setHorario(res[0]);
   return true
  } 

  async function inicializar(){
   var res = await ObtenerAgente(window.localStorage.contrato);
   if(res.error){
     console.log(res);
     return
   }
   console.log("agente", res);
   setAgente(res);
  }

  useEffect( () => {
    inicializar();
  }, []);

  async function buscarHorarioDisponible(){
    var fecha_actual = Date.now();
    for (let i = 0; i < 7; i++) {
      var res = await ObtenerHorariosDisponible(agente.agente, format(fecha_actual, "yyyy/MM/dd"));
      if(res.error){
        console.log(res);
        return
      }

      if(res.length > 0){
        setFecha(format(fecha_actual, "yyyy/MM/dd"));
        setHorarios(res);
        setHorario(res[0]);
        return;
      }
      fecha_actual = addDays(fecha_actual, 1);
    }
  }

  useEffect(()=>{
    buscarHorarioDisponible();
  }, [agente])


  var [agente, setAgente] = useState("");
  var [fecha, setFecha] = useState(format(Date.now(), "yyyy/MM/dd"))
  var [horario, setHorario] = useState("");
  var [motivoCita, setMotivoCita] = useState(citaTopografico ? tipoCitaLlamada[10] : "")
  var [correo, setCorreo] = useState("")
  var [telefono, setTelefono] = useState("")
  var [comentario, setComentario] = useState(citaTopografico ? "SOLICITO LIGA DE PAGO PARA TOPOGRAFIA." : "")
  var [tipoCita, setTipoCita] = useState(citaTopografico ? "telefonica" : "")
  const [motivos, setMotivos] = useState(citaTopografico ? tipoCitaLlamada : [])

  // const tipoCita = useState();



  var [state, setState] = useState({
    registros : [],
    subtotal: 0,
    recargos: 0,
    total: 0,
    mensualidades: ""
  });
  const location = useLocation();


  var procesando = false;
  const agendar = async () => {      
    if(procesando) return;
    procesando = true;
    if(!tipoCita){
      Swal.fire({
          icon: "error",
          title: "Debe seleccionar un tipo de cita.",
      });
      procesando = false;
      return;
    }
    if(!horario){
      Swal.fire({
          icon: "error",
          title: "Debe seleccionar un horario para su cita.",
      });
      procesando = false;
      return;
    }
    if(!motivoCita){
      Swal.fire({
          icon: "error",
          title: "Debe seleccionar un motivo para su cita.",
      });
      procesando = false;
      return;
    }
    if(!correo){
      Swal.fire({
          icon: "error",
          title: "Debe especificar un correo eléctronico, donde le enviaremos la confirmación de su cita.",
      });
      procesando = false;
      return;
    }
    if(!telefono){
      Swal.fire({
          icon: "error",
          title: "Debe especificar un teléfono de contacto.",
      });
      procesando = false;
      return;
    }
    if(!comentario){
      Swal.fire({
          icon: "error",
          title: "Debe especificar un comentario.",
      });
      procesando = false;
      return;
    }
    var data = await CrearCita(window.localStorage.contrato, agente.agente, fecha, horario,
      motivoCita, correo, telefono, comentario, tipoCita, agente.tel_empresa);
    if(data.error)
    {
      Swal.fire({
         icon: "error",
         title: data.error,
      });
      procesando = false;
      return;
    }
    
    var mensaje_extra = "";
    if(tipoCita == "telefonica")
    {
      mensaje_extra = ", recibirá una llamada del número " + agente.tel_empresa;
    }
    else {
      mensaje_extra = ", por favor presentarse 10 min antes";
    }

    await Swal.fire({
      icon: "success",
      title: "Estimado cliente, su cita ha sido agendada" + mensaje_extra
    })

    history.push("/agendar-cita");
  }





  // console.log("registros", registros);
  return (
    <div className="pagoMens-main amistadFondo invertido">
      <Amistad_DatosContrato></Amistad_DatosContrato>
      <div className="pagoMens-container">
         <div className="pagoAsesores-content">
            <div>
               <label htmlFor="">Nombre del agente</label>
               <input type="" value={agente.agente} readOnly/>
            </div>
            <div>
               <label htmlFor="">Fecha</label>
               <Flatpickr 
                  options={{locale:Spanish, altInputClass: "form-control"}}
                  onChange={(e) => {
                    setFecha(format(e[0], "yyyy/MM/dd"));
                    consultarHorario();
                  }} 
                  value={fecha} 
                  // defaultValue={fecha} 
                  // onChange={e=>handleChange("vigencia_inicio", e[0])}
                />
            </div>
            <div>
               <label htmlFor="">Horario</label>
               <select name="" id="" value={horario} onChange={e=> setHorario(e.target.value)} >
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {
                   horarios.map((horario) => 
                    <option value={horario}>{horario}</option>
                    )
                  }
               </select>
            </div>
            <div>
               <label htmlFor="">Tipo de Cita</label>
               <select name="" id="" value={tipoCita} onChange={(e)=>{
                 setTipoCita(e.target.value);
                 if(e.target.value == "telefonica"){
                   setMotivos(tipoCitaLlamada)
                 }
                 else{
                   setMotivos(tipoCitaPresencial)
                 }
               }} >
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  <option value="telefonica" >TELEFÓNICA</option>
                  <option value="presencial" >PRESENCIAL</option>
               </select>
            </div>
            <div>
               <label htmlFor="">Motivo de la cita</label>
               <select name="" id="" value={motivoCita} onChange={e=> setMotivoCita(e.target.value) }>
                  <option value="">SELECCIONE UNA OPCIÓN</option>
                  {
                   motivos.map((motivo) => 
                    <option value={motivo}>{motivo}</option>
                    )
                  }
               </select>
            </div>
            <div>
               <label htmlFor="">Correo</label>
               <input type="email" value={correo} onChange={e=> setCorreo(e.target.value) }/>
            </div>
            <div>
               <label htmlFor="">Número de contacto</label>
               <input type="text" value={telefono}  onChange={e=> setTelefono(e.target.value) }/>
            </div>
            <div className="comment">
               <label htmlFor="">Comentarios</label>
               <textarea name="comentarios" rows="5" cols="30" value={comentario}  onChange={e=> setComentario(e.target.value) } placeholder="Por favor detalle lo mas posible el motivo por el cual esta solicitando la cita."></textarea>
            </div>
         </div>
        <div className="seleccionarPago-prevnext ">
        <Link to="/agendar-cita"><div className="seleccionarPago-prevnext-btn diff">Regresar</div></Link>
          <Link><div className="seleccionarPago-prevnext-btn diff verde" onClick={agendar}>Agendar</div></Link>
        </div>
        {/* <div className="PagoMens-btnsA">
          <div className="seleccionarPago-btnCan2">
            <Link to="/bienvenida">
              <button>Cancelar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnCan2 dos">
            <Link to="/seleccionar-pago">
              <button>Regresar</button>
            </Link>
          </div>
          <div className="seleccionarPago-btnAcep" onClick={realizarPago}>
            <button>Pagar</button>
          </div>
        </div> */}
      </div>
      <Amistad_Footer></Amistad_Footer>
    </div>
  );
}

export default withRouter(Cita_datos);
