import React, { useEffect, useState, useRef } from "react";
import "./Bienvenida.css";
import Amistad_Logos from "./Amistad_Logos";
import amistadlogo from "../res/amistadlogo.png";
import Popup from "reactjs-popup";
import ReportarDeposito from "../components/reportar-deposito";
import ContratoImg from "../res/contrato-imagen.png";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import TerminosCondiciones from "./terminos-condiciones";
import { BuscarNoContrato, ObtenerAsesores, ObtenerContrato, ValidarAsesor } from "../back_helper";
import bienvenidaTarjeta from "../res/contrato-imagen-min.png";
import Amistad_Footer from "./Amistad_Footer";
import logoSantander from "../res/web/logo_santander.png";
import logoGetnet from "../res/web/getnet_logo1.png";

window.Swal = Swal;

function Bienvenida_asesores({ history }) {
  const [datosContrato, setDatosContrato] = useState({
    nombre: "",
    contrato: "",
  });

  const [asesores, setAsesores] = useState([]);

  const contraseñaRef = useRef();
  const asesorRef = useRef();


  const [open1, setOpen1] = useState(false);
  const CerrarModal1 = () => setOpen1(false);
  const busquedaDataRef = useRef({});
  const LoginAsesor = async () => {
    setOpen1(true);
    console.log(asesorRef.current.value, contraseñaRef.current.value);

    var asesor = await ValidarAsesor(asesorRef.current.value, contraseñaRef.current.value);

    console.log("asesor", asesor);

    if (!asesor || asesor.length == 0 || asesor.error) {
      setOpen1(false);
      Swal.fire({
        icon: "error",
        title: "Tu contraseña es incorrecta, intenta de nuevo.",
      });

      return;
    }

    localStorage.asesor = asesorRef.current.value;
    

    setOpen1(false);
    history.push("/pago-asesores");
  };

  const [open2, setOpen2] = useState(false);
  const CerrarModal2 = () => setOpen2(false);
  const AbrirModal2 = () => setOpen2(true);

  const [openTerminos, setOpenTerminos] = useState(false);
  const AbrirModalTerminos = () => setOpenTerminos(true);
  const CerrarModalTerminos = () => setOpenTerminos(false);

  const SeleccionarContrato = () => {};

  const [open3, setOpen3] = useState(false);
  const CloseModal3 = () => setOpen3(false);
  const OpenModal3 = () => setOpen3(true);

  const [open4, setOpen4] = useState(false);
  const CloseModal4 = () => setOpen4(false);
  const OpenModal4 = () => {
    busquedaDataRef.current = {};
    setOpen4(true);
  };

  async function cargarAsesores(){
    var res = await ObtenerAsesores();
    if(res.error){
      console.log(res);
      return;
    }
    setAsesores(res);
  } 
  useEffect(() => {
    
    cargarAsesores();
  }, []);  

  

  const setCampo = (nombre, valor) => {
    busquedaDataRef.current[nombre] = valor;
    console.log(nombre, valor);
  };
  return (
    <>
      <div className="bienvenida-main amistadFondo">
        <Amistad_Logos></Amistad_Logos>
        <div className="bienvenida-container">
          <div className="bienvenida-content asesores">
            <h1>
              Bienvenidos <br />
            </h1>
            <span>pagos en línea amistad</span>
            <h4>Ingresa tus datos de asesor</h4>
            <div className="bienvenida-container-inputs">
              <div className="bienvenidad-inputIcon">
                <select name="" id="" ref={asesorRef}>
                  <option value="">SELECCIONE EL ASESOR</option> 
                  {
                    asesores.map((asesor) => 
                    <option value={asesor.asesor}>{asesor.asesor}</option>
                    )
                  }
                </select>
              </div>
            </div>
            <div className="bienvenida-container-inputs">
              <div className="bienvenidad-inputIcon">
                <input type="password" placeholder="contraseña" ref={contraseñaRef} onKeyDown={ (e) => {
                  if(e.key == "Enter" ){
                     LoginAsesor(); 
                  }
                }} />
                {/* <i className="fas fa-info" onClick={OpenModal3}></i> */}
              </div>
              <div className="bienvenida-btn" onClick={LoginAsesor}>
                {/* <a href="/seleccionar-pago"> */}
                <button>Siguiente</button>
              </div>
            </div>

            {/* <div
              className="bienvenida-teminosC"
              style={{ textAlign: "center", marginTop: "0.3rem" }}
            >
              Al dar en "SIGUIENTE" aceptas nuestros{" "}
              <span
                onClick={AbrirModalTerminos}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                Términos, condiciones y política de privacidad
              </span>
            </div>
            <div
              className="bienvenida-RecuperarContrato"
              style={{ textAlign: "center", marginTop: "1em" }}
            >
              ¿Olvidaste la clave de tu contrato?{" "}
              <span
                onClick={OpenModal4}
                style={{ cursor: "pointer", fontWeight: "600" }}
              >
                Recuperar clave de contrato
              </span>
            </div> */}
            <div className="bienvenida-imgSantander">
              <h5>Con el respaldo del Grupo Financiero Internacional: </h5>
              <img src={logoSantander} alt="imagen logo santander" />
              <img src={logoGetnet} alt="imagen Getnet santander" />
              <br />
              <img
                className="amistad-26y"
                src="https://amistad.z22.web.core.windows.net/Logos/27a.png"
                alt="amistad años"
                style={{ width: "auto", height: "100px" }}
              />
            </div>
            <Popup
              open={openTerminos}
              modal
              closeOnDocumentClick
              onClose={CerrarModalTerminos}
            >
              <div
                className="modal"
                style={{ maxHeight: "90vh", overflow: "auto" }}
              >
                <a className="close" onClick={CerrarModalTerminos}>
                  &times;
                </a>
                <div className="amistad-terminosC">
                  <TerminosCondiciones></TerminosCondiciones>
                </div>
              </div>
            </Popup>
          </div>
        </div>
        <Popup open={open1} modal closeOnDocumentClick onClose={CerrarModal1}>
          <div className="modal">
            <a className="close" onClick={CerrarModal1}>
              &times;
            </a>
            <div className="portal-boton-contrato-popup">
              <i className="far fa-clock"></i>
              <div className="portal-boton-contrato-popuptext">
                Estamos verificando sus datos, espere un momento...
              </div>
            </div>
          </div>
        </Popup>
      </div>
      <Amistad_Footer></Amistad_Footer>
    </>
  );
}

export default  withRouter(Bienvenida_asesores);
