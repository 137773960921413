import React, { useEffect, useState, useRef } from "react";
import "./portal.css";
import amistadlogo from "../res/amistadlogo.png";
import Popup from "reactjs-popup";
import ReportarDeposito from "../components/reportar-deposito";
import ContratoImg from "../res/contrato-imagen.png";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";
import TerminosCondiciones from "./terminos-condiciones";

function Resultado({ history, datosContrato }) {
  // const [open3, setOpen3] = useState(false);
  // const CerrarModal3 = () => setOpen3(false);
  const Continuar = () => {
    localStorage.datosContrato = JSON.stringify(datosContrato);
    history.push("/forma-pago");
  };

  return (
    <>
      <div className="portal-texto1">Resultado</div>
      <div className="portal-cuadro-resultado">
        <div className="portal-campo">
          <div className="portal-cuadro-cliente">
            <div className="portal-nombre">
              Nombre: &nbsp; <span>{datosContrato?.nombre}</span>{" "}
            </div>
            {/* <div className="portal-nombre-cliente">
            José Gutiérrez Alvarado
          </div> */}
          </div>
          <div className="portal-cuadro-contrato">
            <div className="portal-contrato">
              Contrato: &nbsp; <span>{datosContrato?.contrato}</span>{" "}
            </div>
            {/* <div className="portal-contrato-numero">39494449</div> */}
          </div>
        </div>
      </div>
      <div className="portal-boton-resultado" onClick={Continuar}>
        Continuar
      </div>
      {/* <Popup open={open3} modal closeOnDocumentClick onClose={CerrarModal3}>
        <div className="modal">
          <a className="close" onClick={CerrarModal3}>
            &times;
          </a>
          <div className="portal-boton-resultado-popup">
            <i className="far fa-clock"></i>
            <div className="portal-boton-resultado-popuptex">
              Estamos iniciando tu abono, por favor espere...
            </div>
          </div>
        </div>
      </Popup> */}
    </>
  );
}

const contratos_demo = {
  "PED28L14MAR" : {
    nombre: "José Gutiérrez Alvarado",
    contrato: "PED28L14MAR",
    adeudo: 40000,
    mensualidad: 1,
    fecha_limite: "20 de mayo de 2021",
    correo: "sacelx@outlook.com"
  },
  
  "PED28L10ELI" : {
    nombre: "Juán Ramirez Pedroza",
    contrato: "PED28L10ELI",
    adeudo: 40000,
    mensualidad: 2,
    fecha_limite: "20 de mayo de 2021",
    correo: "sacelx@outlook.com"
  },
  
  "PED15L10GUS" : {
    nombre: "Alfonso Godoy Medina",
    contrato: "PED15L10GUS",
    adeudo: 40000,
    mensualidad: 2,
    fecha_limite: "20 de mayo de 2021",
    correo: "sacelx@outlook.com"
  },
  
  "PED11L10JUA" : {
    nombre: "Pedro Romero Chávez",
    contrato: "PED11L10JUA",
    adeudo: 40000,
    mensualidad: 1,
    fecha_limite: "20 de mayo de 2021",
    correo: "sacelx@outlook.com"
  }
}

function Portal({ history }) {
  const [datosContrato, setDatosContrato] = useState({
    nombre: "",
    contrato: "",
  });


  const noContratoRef = useRef();

  const [open1, setOpen1] = useState(false);
  const CerrarModal1 = () => setOpen1(false);
  const BuscarContrato = () => {
    setOpen1(true);
    var nocontrato = noContratoRef.current.value;
    console.log(nocontrato);
    if (contratos_demo[nocontrato]) {
      setOpen1(false);
      setDatosContrato(contratos_demo[nocontrato]);
    } else {
      setOpen1(false);
      Swal.fire({
        icon: "error",
        title: "No se encontró ese número de contrato",
      });
    }
  };

  const [open2, setOpen2] = useState(false);
  const CerrarModal2 = () => setOpen2(false);
  const AbrirModal2 = () => setOpen2(true);
  
  const [openTerminos, setOpenTerminos] = useState(false);
  const AbrirModalTerminos = () => setOpenTerminos(true);
  const CerrarModalTerminos = () => setOpenTerminos(false);


  return (
    <div className="contenido-main">
      <div className="portal-imagen">
        <img
          src={amistadlogo}
          alt="Banner Amistad"
          width="200px"
          height="150"
        />
      </div>
      <div className="portal-contenido">
        <div className="portal-texto1">Consulta el monto de tu adeudo</div>
        <div className="portal-cuadro-buscador">
          <div className="portal-subtitulo-buscador">
            Ingresa tu número de contrato{" "}
          </div>
          <div className="portal-campo">
            <div className="portal-cuadro-contrato">
              <input
                ref={noContratoRef}
                type="text"
                className="portal-numero-contrato"
                placeholder="No. de contrato"
              />
              <i className="fa fa-info-circle" onClick={AbrirModal2}></i>
              <Popup
                open={open2}
                modal
                closeOnDocumentClick
                onClose={CerrarModal2}
              >
                <div className="modal">
                  <a className="close" onClick={CerrarModal2}>
                    &times;
                  </a>
                  <div className="portal-icon-popup">
                    <img src={ContratoImg} alt="Contrato imagen" />
                  </div>
                </div>
              </Popup>
            </div>
            <div className="portal-boton-contrato" onClick={BuscarContrato}>
              Buscar
            </div>
            <Popup
              open={open1}
              modal
              closeOnDocumentClick
              onClose={CerrarModal1}
            >
              <div className="modal">
                <a className="close" onClick={CerrarModal1}>
                  &times;
                </a>
                <div className="portal-boton-contrato-popup">
                  <i className="far fa-clock"></i>
                  <div className="portal-boton-contrato-popuptext">
                    Estamos buscando tu adeudo, espere un momento...
                  </div>
                </div>
              </div>
            </Popup>
            <div>
              <div style={{textAlign:"left", marginTop:"0.5rem"}}>Al continuar aceptas nuestros <span onClick={AbrirModalTerminos} style={{cursor:"pointer", color:"blue", fontWeight:"600"}}>Términos, condiciones y política de privacidad</span></div>
              <Popup
                open={openTerminos}
                modal
                closeOnDocumentClick
                onClose={CerrarModalTerminos}
              >
                <div className="modal" style={{maxHeight:"90vh", overflow:"auto"}}>
                  <a className="close" onClick={CerrarModalTerminos}>
                    &times;
                  </a>                  
                  <div>
                    <TerminosCondiciones></TerminosCondiciones>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
        {datosContrato && datosContrato.nombre && (
          <Resultado datosContrato={datosContrato} history={history}></Resultado>
        )}
      </div>
    </div>
  );
}
export default withRouter(Portal);
