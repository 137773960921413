import React from "react";
import "./Amistad_Footer.css";
import Desarrollo1 from "../res/Logos_monocromaticos/desarrollo1.png";
import Desarrollo2 from "../res/Logos_monocromaticos/desarrollo2.png";
import Desarrollo3 from "../res/Logos_monocromaticos/desarrollo3.png";
import Desarrollo4 from "../res/Logos_monocromaticos/desarrollo4.png";
import Desarrollo5 from "../res/Logos_monocromaticos/desarrollo5.png";
import Desarrollo6 from "../res/Logos_monocromaticos/desarrollo6.png";
import Desarrollo7 from "../res/Logos_monocromaticos/desarrollo7.png";
import Desarrollo8 from "../res/Logos_monocromaticos/desarrollo8.png";
import Desarrollo9 from "../res/Logos_monocromaticos/desarrollo9.png";
import ImgFb from "../res/web/fb.png";
import ImgIg from "../res/web/ig.png";
import ImgTg from "../res/web/tg.png";
import ImgTt from "../res/web/tt.png";
import ImgW from "../res/web/w.png";
import ImgYt from "../res/web/yt.png";

import {correo} from "./Amistad_Logos";

var imagenes = [
  Desarrollo1,
  Desarrollo2,
  Desarrollo3,
  Desarrollo4,
  Desarrollo5,
  Desarrollo6,
  Desarrollo7,
  Desarrollo8,
  Desarrollo9
];
function Amistad_Footer() {
  return (
    <div className="Amistad-footer">
      <div className="amistadFooter-content">
        <div className="redes">
          <h1>Síguenos:</h1>
          <ul>
            <li>
              <a href="">
                <img src={ImgTg} alt="imagen telegram amistad" target="_blank"/>
              </a>
            </li>
            <li>
              <a href="https://www.tiktok.com/@amistadbr" target="_blank">
              <img src={ImgTt} alt="imagen tiktok amistad" />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/amistadbr/" target="_blank">
              <img src={ImgFb} alt="imagen facebook amistad" />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/amistadbr/" target="_blank">
              <img src={ImgIg} alt="imagen instagram amistad" />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCHMljReTydWXmp-ZyTg6j-Q" target="_blank">
              <img src={ImgYt} alt="imagen youtube amistad" />
              </a>
            </li>
            <li>
              <a href="https://api.whatsapp.com/send?phone=5219981514221" target="_blank">
              <img src={ImgW} alt="imagen whatsapp amistad" />
              </a>
            </li>
          </ul>
        </div>
        <div className="correo">
          <h1>Correo electrónico:</h1>
          <ul>
            <li></li>
            <li className="correo-email">{correo}</li>
          </ul>
        </div>
        <div className="amistadFooter-marcas-container">
          <h1>Nuestros desarrollos:</h1>
          <div className="amistadFooter-marcas">
            {imagenes.map((url) => (
              <img src={url}></img>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Amistad_Footer;
